// material-ui
import { Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function ColoredLabel({ text, backgroundColor, fontSize }) {
    return (
        <Box
            sx={{
                backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                justifySelf: 'center',
                padding: '4px 12px',
                width: 'fit-content'
            }}
        >
            <Typography
                component="span"
                sx={{
                    fontWeight: '400',
                    fontSize: fontSize || '16px',
                    color: 'white'
                }}
            >
                {text}
            </Typography>
        </Box>
    );
}

ColoredLabel.propTypes = {
    text: PropTypes.string,
    backgroundColor: PropTypes.string,
    fontSize: PropTypes.any
};
