import { Typography, Grid, Stack } from '@mui/material';
import ColoredLabel from './ColoredLabel';
import PropTypes from 'prop-types';

const styles = {
    title: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#0A0A0A'
    },
    label: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#0A0A0A',
        opacity: '0.5'
    },
    data: {
        fontSize: {
            xs: '14px',
            sm: '16px'
        },
        fontWeight: '500',
        color: '#0A0A0A'
    }
};
// if backgroundColor is set, it will use a ColoredLabel, if not, regular text
export default function InfoItem({ label, data, backgroundColor, sup }) {
    return (
        <Grid item xs={6} sm={6} lg={4}>
            <Grid container rowSpacing={backgroundColor ? '8px' : '12px'}>
                <Grid item xs={12}>
                    <Typography sx={styles.label}>{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    {backgroundColor ? (
                        <ColoredLabel text={data} backgroundColor={backgroundColor} fontSize={styles.data.fontSize} />
                    ) : (
                        <Stack direction="row">
                            <Typography sx={styles.data}>{data}</Typography>
                            {sup && <Typography sx={{ ...styles.data, fontSize: '14px', lineHeight: 1 }}>{sup}</Typography>}
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

InfoItem.propTypes = {
    label: PropTypes.string,
    data: PropTypes.any,
    backgroundColor: PropTypes.string,
    sup: PropTypes.string
};
