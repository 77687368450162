/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// material-ui
import { Box, Grid, Stack, Icon, Typography } from '@mui/material';
import { CheckCircle, LocalShipping, Pending } from '@mui/icons-material';
// react
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// project imports
// import Gmap from 'ui-component/google-map/GoogleMap';
import { GoogleMap } from '@wavey-desu/muub-components';
import { objectIsEmpty } from '../../../../utils/functions';
// import status from 'store/status';

// styles
const styles = {
    title: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#0A0A0A'
    }
};
const defaultCenter = { lat: 25.66, lng: -100.3396 };

// main component
export default function StatusMapSection() {
    const { shipmentById: shipment, isShipmentByIdLoading: loading } = useSelector((state) => state.shipments);
    const phases = useMemo(() => {
        if (shipment && !objectIsEmpty(shipment)) return shipment.statusHistory;
        return [];
    }, [shipment]);

    const indexCurrentPhase = useMemo(() => {
        const index = phases.findIndex((p) => p.Description === shipment.phase);
        return index < 0 ? 0 : index;
    }, [phases, shipment]);

    const [currentCords, setCurrentCords] = useState({ lat: '', lng: '' });
    const [mod, setMod] = useState('');

    useEffect(() => {
        if (!loading) {
            const { modality } = shipment;
            setMod(modality);
        }
    }, [loading, shipment, mod]);

    useEffect(() => {
        if (shipment.statusHistory) {
            const currentCordsTemp = shipment.statusHistory[indexCurrentPhase]?.Location;
            if (currentCordsTemp) {
                setCurrentCords({
                    lat: currentCordsTemp.Latitude,
                    lng: currentCordsTemp.Longitude
                });
            }
        }
    }, [shipment, indexCurrentPhase]);

    return (
        <Grid
            container
            sx={{
                width: '100%',
                backgroundColor: 'white',
                padding: '0 31px'
            }}
        >
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    backgroundColor: 'white',
                    overflow: 'auto',
                    height: '330px'
                }}
            >
                <Typography
                    sx={{
                        ...styles.title,
                        paddingBottom: '20px',
                        position: 'sticky',
                        top: '0',
                        backgroundColor: 'white',
                        zIndex: '10'
                    }}
                >
                    Current Status
                </Typography>
                <Stack>
                    {phases &&
                        phases.map((phase, i) => (
                            <Box
                                key={i}
                                sx={{
                                    paddingBottom: '31px',
                                    paddingTop: '1px'
                                }}
                            >
                                <Stack direction="row" spacing="15px" alignItems="center">
                                    <Icon
                                        sx={{
                                            position: 'relative',
                                            width: '30px',
                                            height: '30px',
                                            overflow: 'visible',

                                            '&::after': {
                                                borderRight: '2px dashed #D8D8D8',
                                                height: '65px',
                                                width: '0px',
                                                position: 'absolute',
                                                display: 'block',
                                                top: '100%',
                                                left: 'calc(50% - 1px)',
                                                content: '""',
                                                zIndex: '0'
                                            }
                                        }}
                                    >
                                        {i < indexCurrentPhase && (
                                            <CheckCircle
                                                sx={{
                                                    color: '#01C5BA',
                                                    fontSize: '30px',
                                                    backgroundColor: 'white'
                                                }}
                                            />
                                        )}
                                        {indexCurrentPhase === i && (
                                            <LocalShipping
                                                sx={{
                                                    color: '#FFFFFF',
                                                    fontSize: '25px',
                                                    borderRadius: '50%',
                                                    padding: '4px',
                                                    backgroundColor: '#145DF2'
                                                }}
                                            />
                                        )}
                                        {i > indexCurrentPhase && (
                                            <Pending
                                                sx={{
                                                    color: '#FFFFFF',
                                                    fontSize: '25px',
                                                    borderRadius: '50%',
                                                    padding: '4px',
                                                    backgroundColor: 'grey'
                                                }}
                                            />
                                        )}
                                    </Icon>
                                    <Stack spacing="5px">
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                color: '#0A0A0A'
                                            }}
                                        >
                                            {phase.Description}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        ))}
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                // sx={{
                //     height: '330px',
                //     borderRadius: '20px',
                //     overflow: 'hidden'
                // }}
            >
                <Box
                    sx={{
                        borderRadius: '20px',
                        height: '100%',
                        overflow: 'hidden',
                        marginLeft: {
                            xs: '0',
                            sm: '11px'
                        },
                        marginTop: {
                            xs: '20px',
                            sm: '0'
                        }
                    }}
                >
                    <GoogleMap
                        location={currentCords.lat && currentCords.lng ? currentCords : defaultCenter}
                        marker1={currentCords.lat && currentCords.lng ? currentCords : null}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
