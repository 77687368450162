/* eslint-disable arrow-body-style */
// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
// const theme = useTheme();
import logoVonto from '../assets/images/LogoVonto.svg';

const Logo = () => {
    return <img src={logoVonto} alt="Vonto Logo" width="140" />;
};

export default Logo;
