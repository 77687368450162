import useAuth from 'hooks/useAuth';
import { PropTypes } from 'prop-types';
import Dashboard from 'views/dashboard/index';
import { useNavigate } from 'react-router-dom';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'store/index';

// import UnauthorizedPage from 'views/pages/UnauthorizedPage';

const RoleGuard = ({ roles, children }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // console.log(user);

    if (roles.includes(user.role)) {
        // console.log('Authorized');
        return children;
    }
    navigate('/dashboard', { replace: true });
    dispatch(
        openSnackbar({
            open: true,
            message: 'Unauthorized Access',
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            transition: 'SlideDown',
            variant: 'alert',
            alert: {
                color: 'error'
            },
            close: false
        })
    );

    return <Dashboard />;
};

export default RoleGuard;

RoleGuard.propTypes = {
    roles: PropTypes.any,
    children: PropTypes.any
};
