/* eslint-disable prefer-template */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// API
import { useDispatch, useSelector } from 'store';
import { getShipments } from 'store/slices/shipments';
import { getCustomers } from 'store/slices/quotes';
// MUI
import {
    FormControl,
    MenuItem,
    Select,
    Stack,
    Popover,
    ButtonBase,
    Typography,
    InputLabel,
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    TextField
} from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { TuneRounded, HighlightOff, ArrowDropDown } from '@mui/icons-material';
// 3rd party
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';

const dateConvert = (date) => {
    const d = new Date(date);

    const newDate = [d.getFullYear(), ('0' + (d.getMonth() + 1)).slice(-2), ('0' + d.getDate()).slice(-2)].join('-');
    return newDate;
};

function addOneYear(date) {
    date.setFullYear(date.getFullYear() + 1);
    return date;
}
export default function FilterBox() {
    const currentDate = new Date();
    const dispatch = useDispatch();
    // value states
    const [calendarStateArrival, setCalendarStateArrival] = useState([
        {
            startDate: null,
            endDate: addOneYear(currentDate),
            key: 'selection'
        }
    ]);
    const [calendarStateDelivery, setCalendarStateDelivery] = useState([
        {
            startDate: null,
            endDate: addOneYear(currentDate),
            key: 'selection'
        }
    ]);
    const [mod, setMod] = useState('');
    const [status, setStatus] = useState('');
    const [impExp, setImpExp] = useState('');
    const [custId, setCustId] = useState('');
    const [custFilter, setCustFilter] = useState('');

    // Accordion mngmnt
    const [expanded, setExpanded] = useState(false);

    const handlePannelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        dispatch(getCustomers(custFilter));
    }, [custFilter]);
    const { customerData } = useSelector((state) => state.quotes);

    // API call
    useEffect(() => {
        dispatch(
            getShipments(
                '',
                '',
                status,
                mod,
                dateConvert(calendarStateArrival[0]?.startDate),
                dateConvert(calendarStateArrival[0]?.endDate),
                dateConvert(calendarStateDelivery[0]?.startDate),
                dateConvert(calendarStateDelivery[0]?.endDate),
                impExp,
                custId
            )
        );
    }, [mod, status, impExp, calendarStateArrival, calendarStateDelivery, custId]);

    const clearFilter = () => {
        setMod('');
        setStatus('');
        setImpExp('');
        setCustId('');
        setCalendarStateArrival([
            {
                startDate: null,
                endDate: currentDate,
                key: 'selection'
            }
        ]);
        setCalendarStateDelivery([
            {
                startDate: null,
                endDate: currentDate,
                key: 'selection'
            }
        ]);
    };

    // console.log(dateConvert(calendarStateDelivery[0]?.startDate));

    return (
        <PopupState variant="popover" popupId="shipemntFilters">
            {(popupState) => (
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                    <ButtonBase sx={{ width: '100%', borderRadius: '8px', height: '100%' }} {...bindTrigger(popupState)}>
                        <Stack
                            sx={{
                                width: '100%',
                                background: 'white',
                                borderRadius: '8px',
                                border: '1px solid rgba(147, 166, 192, 0.35)',
                                height: '50px'
                            }}
                            spacing="12px"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TuneRounded
                                sx={{
                                    fontSize: '20px'
                                }}
                            />
                            {/* <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    color: '#0A0A0A'
                                }}
                            >
                                {' '}
                                Filter
                            </Typography> */}
                        </Stack>
                    </ButtonBase>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        sx={{ mt: 1 }}
                        PaperProps={{
                            sx: {
                                width: '350px',
                                pb: 3,
                                '&::-webkit-scrollbar': { width: '5px' },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: (theme) => theme.palette.grey[300],
                                    borderRadius: '20px'
                                }
                            }
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                pt: 1
                            }}
                        >
                            <FormControl variant="standard" sx={{ width: '90%' }}>
                                <InputLabel>Modality</InputLabel>
                                <Select value={mod} onChange={(e) => setMod(e.target.value)} label="Modality">
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="AIR">Air</MenuItem>
                                    <MenuItem value="SEA">Ocean</MenuItem>
                                    <MenuItem value="LAND">Ground</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: '90%' }}>
                                <InputLabel>Import/Export</InputLabel>
                                <Select value={impExp} onChange={(e) => setImpExp(e.target.value)} label="Import/Export">
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value={true}>Import</MenuItem>
                                    <MenuItem value={false}>Export</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl variant="standard" sx={{ width: '90%' }}>
                                <InputLabel>Status</InputLabel>
                                <Select value={status} onChange={(e) => setStatus(e.target.value)} label="Status">
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value="CREATED">Created</MenuItem>
                                    <MenuItem value="IN TRANSIT">In-Transit</MenuItem>
                                    <MenuItem value="DELIVERED">Delivered</MenuItem>
                                </Select>
                            </FormControl>
                            <Stack direction="column" spacing={1.5} sx={{ width: '90%' }}>
                                <Autocomplete
                                    freeSolo
                                    // disabled={!mod}
                                    disablePortal
                                    options={customerData?.data || []}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => {
                                        setCustId(value.id);
                                        console.log(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            id="customer"
                                            name="customer"
                                            value={custId}
                                            onChange={(e) => setCustFilter(e.target.value)}
                                            label="Customer"
                                        />
                                    )}
                                />
                            </Stack>
                            <Accordion expanded={expanded === 'panel2'} onChange={handlePannelChange('panel2')}>
                                <AccordionSummary expandIcon={<ArrowDropDown sx={{ mr: 1 }} />}>
                                    <Typography color="GrayText" sx={{ ml: 1 }}>
                                        Estimated Departure Date
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DateRange
                                        editableDateInputs={false}
                                        onChange={(item) => setCalendarStateDelivery([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={calendarStateDelivery}
                                        showDateDisplay={false}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel1'} onChange={handlePannelChange('panel1')}>
                                <AccordionSummary expandIcon={<ArrowDropDown sx={{ mr: 1 }} />}>
                                    <Typography color="GrayText" sx={{ ml: 1 }}>
                                        Estimated Arrival Date
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DateRange
                                        editableDateInputs={false}
                                        onChange={(item) => setCalendarStateArrival([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={calendarStateArrival}
                                        showDateDisplay={false}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Button variant="text" onClick={() => clearFilter()} startIcon={<HighlightOff />}>
                                Clear
                            </Button>
                        </Stack>
                    </Popover>
                </Stack>
            )}
        </PopupState>
    );
}

FilterBox.propTypes = {
    deliveryStageFilter: PropTypes.any,
    setDeliveryStageFilter: PropTypes.any
};
