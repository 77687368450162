import { API_URL, parcelsApiKey } from '../config';
import axiosFactory from './axiosFactory';
import axios from 'axios';
// import { useSelector } from 'store';

const VONTO_URL = API_URL.vontoLink; // vonto
const VONTO_USER_ACCESS_URL = API_URL.userAccess;
const VONTO_PEOPLE_MODULE_URL = API_URL.people;
const VONTO_CATALOG_URL = API_URL.catalogs;
const VONTO_PARCELS_URL = API_URL.parcels;

class HeaderBuilder {
    headers = {};

    addTenantId(tenantId) {
        this.headers['X-Tenant-Id'] = tenantId;
        return this;
    }

    addAppId(appId) {
        this.headers['X-App-Id'] = appId;
        return this;
    }

    build() {
        const headersBuild = this.headers;
        this.headers = {};
        return headersBuild;
    }
}

const headersBuilder = new HeaderBuilder();

const hostname = window.location.hostname;
const tenant = hostname.split('.')[0];

const axiosVonto = axiosFactory({
    url: VONTO_URL,
    headers: headersBuilder.addTenantId(tenant).addAppId('app-vonto-link').build()
});

const axiosPeople = axiosFactory({
    url: VONTO_PEOPLE_MODULE_URL,
    headers: headersBuilder.build()
});

const axiosUserAccess = axiosFactory({
    url: VONTO_USER_ACCESS_URL,
    headers: headersBuilder.addTenantId(tenant).addAppId('app-vonto-link').build()
});

const axiosCatalog = axiosFactory({
    url: VONTO_CATALOG_URL,
    headers: {}
});

const axiosParcelsAPI = axiosFactory({
    url: VONTO_PARCELS_URL
});

const axiosParcels = axios.create({
    baseURL: `https://api.airtable.com/v0/${parcelsApiKey}/`,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`
    }
});

const axiosPost = axios.create({
    baseURL: `https://api.airtable.com/v0/${parcelsApiKey}/`,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_KEY}`,
        'Content-Type': 'application/json'
    }
});

export { axiosCatalog, axiosUserAccess, axiosVonto, axiosPeople, axiosParcelsAPI, axiosParcels, axiosPost };
