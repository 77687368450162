// material-ui
import { Box, Divider } from '@mui/material';
// components
import OriginDeliverySection from './OriginDeliverySection';
import ShipmentTripInformationSection from './ShipmentTripInformationSection';
import ShipperScheduleSection from './ShipperScheduleSection';
import StatusMapSection from './StatusMapSection';
// import Files from 'views/shipments/components/Files';

const dividerSx = {
    margin: '32px 31px',
    borderColor: 'rgba(217, 224, 233, 0.7)'
};

export default function InformationView() {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'white',
                padding: '40px 0',
                borderRadius: '8px'
            }}
        >
            {/* Origin delivery section */}
            <OriginDeliverySection />

            {/* Divider */}
            <Divider sx={dividerSx} />

            {/* Shipment/Trip information section */}

            <ShipmentTripInformationSection />

            {/* Divider */}
            <Divider sx={dividerSx} />

            {/* Shipper/Schedule Information section */}

            <ShipperScheduleSection />
            {/* Divider */}
            <Divider sx={dividerSx} />

            <StatusMapSection />

            <Divider sx={dividerSx} />
            {/* <Files /> */}
        </Box>
    );
}
