// assets
import { IconLayoutDashboard } from '@tabler/icons';

// constant
const icons = {
    IconLayoutDashboard
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard-section',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconLayoutDashboard,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
