import { Stack, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
// Returns the view switch using MUI tabs
export default function ViewSwitch({ setCurrentView, currentView }) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent={{
                xs: 'center',
                sm: 'flex-start'
            }}
        >
            <Tabs
                value={currentView}
                onChange={(e, newV) => setCurrentView(newV)}
                aria-label="basic tabs example"
                sx={{
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#145DF2'
                    }
                }}
            >
                <Tab
                    label="Information"
                    value="Information"
                    sx={{
                        '&.Mui-selected': {
                            color: '#145DF2'
                        }
                    }}
                />
                {/* <Tab
                    label="Detailed Status"
                    value="DetailedStatus"
                    sx={{
                        '&.Mui-selected': {
                            color: '#145DF2'
                        }
                    }}
                /> */}
                {/* <Tab
                    label="Invoice"
                    value="Invoice"
                    sx={{
                        '&.Mui-selected': {
                            color: '#145DF2'
                        }
                    }}
                /> */}
            </Tabs>
        </Stack>
    );
}

ViewSwitch.propTypes = {
    currentView: PropTypes.any,
    setCurrentView: PropTypes.any
};
