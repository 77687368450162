const tokenKey = 'serviceToken';

const tokenServiceFactory = () => {
    let token = null;

    const getToken = () => {
        if (!token) {
            token = localStorage.getItem(tokenKey);
        }
        return token;
    };

    const setToken = (newToken) => {
        token = newToken;
        if (!newToken) {
            localStorage.removeItem(tokenKey);
        } else {
            localStorage.setItem(tokenKey, newToken);
        }
    };

    return {
        getToken,
        setToken
    };
};

const tokenService = tokenServiceFactory();

export default tokenService;
