import PropTypes from 'prop-types';
// material-ui
import {
    Box,
    Divider,
    Typography,
    TableHead,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Table,
    Skeleton,
    CssBaseline
} from '@mui/material';
// components
import StatusBar from './StatusBar';
// utils
import { useSelector } from 'store';
import { useEffect, useMemo, useState } from 'react';
import { Global } from '@emotion/react';
import ColoredLabel from '../../../../ui-component/components/ColoredLabel';
import waitingIcon from '../../../../assets/images/shipments/waiting.png';
import checkIcon from '../../../../assets/images/shipments/check.png';
import currentIcon from '../../../../assets/images/shipments/current.png';

const drawerBleeding = 0;

// Styles for the table
const styles = {
    tableHeader: {
        fontWeight: '700',
        color: '#0A0A0A',
        opacity: 0.5,
        fontSize: '16px'
    },
    bodyBlack: {
        fontWeight: '400',
        color: '#0A0A0A',
        fontSize: '16px'
    },
    bodyGray: {
        fontWeight: '400',
        color: '#0A0A0A',
        opacity: 0.5,
        fontSize: '16px'
    }
};

// Columns for the table
// Each column has a name, a getter function, and a width
// The getter function is used to render the data in the column
const columns = [
    {
        name: 'Date',
        getter: (col) => <Typography sx={styles.bodyGray}>{col?.UpdatedAt || '-'}</Typography>,
        width: '230px'
    },
    {
        name: 'Location',
        getter: (col) => <Typography sx={styles.bodyBlack}>{col?.Location || '-'}</Typography>,
        width: '400px'
    },
    {
        name: 'Event',
        width: '400px'
    },
    {
        name: 'Status',
        width: '100px'
    }
];

const StatusImageIcon = ({ src, alt }) => (
    <>
        <img src={src} alt={alt} style={{ width: '2rem' }} />
    </>
);

StatusImageIcon.propTypes = {
    src: PropTypes.any,
    alt: PropTypes.string
};

const WatingImageIcon = () => <StatusImageIcon src={waitingIcon} alt="wating" />;
const CheckImageIcon = () => <StatusImageIcon src={checkIcon} alt="checked" />;
const CurrentImageIcon = () => <StatusImageIcon src={currentIcon} alt="current" />;

function findIndexCurrentPhase(phases) {
    let index = phases.length - 1;
    for (; index >= 0; index -= 1) {
        console.log(phases[index]);
        if (phases[index].OccurredOn) {
            return index;
        }
    }

    return index;
}

export default function DetailedStatusView() {
    const { shipmentById: shipment, isShipmentByIdLoading: loading } = useSelector((state) => state.shipments);
    const phaseOptions = useMemo(() => shipment?.statusHistory ?? [], [shipment]);

    const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);

    const determineImageType = (index) => {
        if (index === currentPhaseIndex) return <CurrentImageIcon />;

        if (index > currentPhaseIndex) return <WatingImageIcon />;

        return <CheckImageIcon />;
    };

    useEffect(() => {
        if (phaseOptions.length > 0) {
            const found = findIndexCurrentPhase(phaseOptions);
            setCurrentPhaseIndex(found);
        }
    }, [phaseOptions]);
    // This is used only for the example
    return (
        <Box
            sx={{
                padding: '32px 10px',
                width: '100%',
                backgroundColor: '#FFFFFF'
            }}
        >
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        width: `calc(40% - ${drawerBleeding}px)`,
                        overflow: 'visible'
                    }
                }}
            />
            <Box>
                {loading ? (
                    <>
                        <Skeleton variant="rounded" height={40} />
                    </>
                ) : (
                    <Box sx={{ width: '100%', overflowX: 'scroll' }}>
                        <StatusBar currentPhase={shipment.currentStatus} />
                    </Box>
                )}
                <Divider
                    sx={{
                        margin: {
                            xs: '24px 0px',
                            sm: '32px 0 32px 0'
                        }
                    }}
                />

                <Typography variant="h4" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                    Updates:
                </Typography>
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto'
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.name}
                                        sx={{
                                            minWidth: column.width
                                        }}
                                    >
                                        <Typography sx={styles.tableHeader}>{column.name}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shipment?.statusHistory.map((phase, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <ColoredLabel
                                            text={phase.OccurredOn ? new Date(phase.OccurredOn).toLocaleString() : '-'}
                                            fontSize={14}
                                            backgroundColor="#3f51b5"
                                        />
                                    </TableCell>
                                    <TableCell>{phase.Location?.FullAddress || '-'}</TableCell>
                                    <TableCell>{phase.Description || '-'}</TableCell>
                                    <TableCell>{determineImageType(i)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
