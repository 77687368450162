/* eslint-disable no-unneeded-ternary */
import { createSlice } from '@reduxjs/toolkit';
// import axiosInstance from 'hooks/axiosInstance';
import { axiosVonto } from 'hooks/axiosInstance';
import { dispatch } from '../index';
// import { DateProfileGenerator } from '@fullcalendar/react';

const initialState = {
    shipments: [],
    shipmentById: {},
    isShipmentByIdLoading: true,
    modalityStats: [],
    statusStats: [],
    destinationStats: [],
    originStats: [],
    allStats: [],
    totalShipmentsByVendor: [],
    vendorShipmentsByImport: [],
    vendorShipmentsByModality: [],
    vendorShipmentsPricing: [],
    vendorShipmentsKg: [],
    vendorShipementsPrice: [],
    shipmentDocs: {},
    vendorsList: [],
    KPIshipments: []
};

const slice = createSlice({
    name: 'shipments',
    initialState,
    reducers: {
        getShipments(state, action) {
            state.shipments = action.payload;
        },
        setShipmentById(state, action) {
            state.shipmentById = action.payload;
        },
        setIsShipmentByIdLoading(state, action) {
            state.isShipmentByIdLoading = action.payload;
        },
        setStats(state, action) {
            const key = action.payload.groupBy === '' ? 'allStats' : `${action.payload.groupBy}Stats`;
            let copy = state[key];
            copy = action.payload.data.slice();
            state[key] = copy;
        },
        setShipmentsByVendor(state, action) {
            state.totalShipmentsByVendor = action.payload;
        },
        setVendorShipmentsByImport(state, action) {
            state.totalShipmentsByVendor = action.payload;
        },
        setVendorShipmentsByModality(state, action) {
            state.vendorShipmentsByModality = action.payload;
        },
        setVendorShipmentsKg(state, action) {
            state.vendorShipmentsKg = action.payload;
        },
        setVendorShipentPrices(state, action) {
            state.vendorShipementsPrice = action.payload;
        },
        setShipmentDocs(state, action) {
            state.shipmentDocs = action.payload;
        },
        setVendors(state, action) {
            state.vendorsList = action.payload;
        },
        setKPIShipments(state, action) {
            state.KPIshipments = action.payload;
        }
    }
});

export default slice.reducer;

export function getShipments(
    vendorId,
    isShipper,
    status,
    modality,
    initialArrivalDate,
    finalArrivalDate,
    initalDeliveryDate,
    finalDeliveryDate,
    Import,
    CustomerId
) {
    const params = new URLSearchParams([
        ['VendorId', vendorId || ''],
        ['isShipper', isShipper],
        ['Status', status || ''],
        ['Modality', modality || ''],
        ['InitialDateETA', initialArrivalDate || ''],
        ['FinalDateETA', finalArrivalDate || ''],
        ['InitialDateETD', initalDeliveryDate || ''],
        ['FinalDateETD', finalDeliveryDate || ''],
        ['Import', Import || ''],
        ['CustomerId', CustomerId || '']
    ]);

    return async () => {
        try {
            const respose = await axiosVonto.instance.get('/api/shipping/shipments', { params });
            dispatch(slice.actions.getShipments(respose.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getKPIShipments(initialArrivalDate, finalArrivalDate, initalDeliveryDate, finalDeliveryDate) {
    const params = new URLSearchParams([
        ['VendorId', ''],
        ['isShipper', ''],
        ['Status', ''],
        ['Modality', ''],
        ['InitialDateETA', initialArrivalDate || ''],
        ['FinalDateETA', finalArrivalDate || ''],
        ['InitialDateETD', initalDeliveryDate || ''],
        ['FinalDateETD', finalDeliveryDate || ''],
        ['Import', ''],
        ['CustomerId', '']
    ]);

    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/shipping/shipments', { params });
            dispatch(slice.actions.setKPIShipments(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getShipmentById(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/shipments/${id}`);
            dispatch(slice.actions.setShipmentById(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postShipment(mod, values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post(`/api/shipping/shipments/${mod}`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function makeEmptyShipment() {
    return () => dispatch(slice.actions.setShipmentById({}));
}

export function setIsShipmentByIdLoading(value) {
    return () => dispatch(slice.actions.setIsShipmentByIdLoading(value));
}

export async function getShipmentsStats(groupBy, initialDate, endDate) {
    try {
        const params = new URLSearchParams([
            ['GroupBy', groupBy ?? ''],
            ['InitalDate', initialDate?.toISOString() ?? ''],
            ['FinalDate', endDate?.toISOString() ?? ''],
            ['DateFilterTypes', 'EstimatedTimeOfDeparture']
        ]);
        const response = await axiosVonto.instance.get(`/api/shipping/stats/shipments`, { params });
        dispatch(slice.actions.setStats({ data: response.data, groupBy }));
    } catch (error) {
        console.error(error);
    }
}

export function getShipmentsByVendor(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/stats/shipments?VendorId=${id}`);
            dispatch(slice.actions.setShipmentsByVendor(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendorShipmentsByImport(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/shipping/stats/shipments', { GroupBy: 'Import', VendorId: id });
            dispatch(slice.actions.setVendorShipmentsByImport(response));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendorShipmentsByMod(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/shipping/stats/shipments', { Groupby: 'Modality', VendorId: id });
            dispatch(slice.actions.setVendorShipmentsByModality(response));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendorShipmentsByKgImport(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/stats/shipments/kg?VendorId=${id}&GroupBy=Import`);
            dispatch(slice.actions.setVendorShipmentsKg(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendorShipmentsPrices(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/stats/shipments/prices?VendorId=${id}&GroupBy=Import`);
            dispatch(slice.actions.setVendorShipentPrices(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function updateShipmentPhase(id, values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.patch(`/api/shipping/shipments/status/avance-at/${id}`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getShipmentFiles(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/Shipments/files/get-by-shipment-id/${id}`);
            dispatch(slice.actions.setShipmentDocs(response.data));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.setShipmentDocs({}));
        }
    };
}

export function postShipmentDocs(id, type, doc, callback) {
    const formData = new FormData();

    formData.append('Type', type);
    formData.append('FormFile', doc);
    return async () => {
        try {
            const response = await axiosVonto.instance.patch(`/api/shipping/Shipments/files/add/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendors() {
    const params = new URLSearchParams([
        ['Page', 1],
        ['PageSize', 1000]
    ]);

    return async () => {
        try {
            const response = await axiosVonto.instance.get('api/people/vendors', { params });
            dispatch(slice.actions.setVendors(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function postVendors(values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post('api/people/vendors', values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
