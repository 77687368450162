import main from './main';
import dashboard from './dashboard';
import settings from './settings';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, main, settings]
};

export default menuItems;
