export const SHIPMENT_MODALITIES = {
    air: 'AIR',
    sea: 'SEA',
    road: 'ROAD'
};

export const SHIPMENT_STATUS = {
    created: 'CREATED',
    inTransit: 'IN TRANSIT',
    delivered: 'DELIVERED'
};
