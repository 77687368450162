// material-ui
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';

// component
import ShipmentCard from './ShipmentCard';
// Just a grid container for the shipment cards.
export default function ShipmentCards({ shipmentList }) {
    return (
        <Box>
            <Grid container spacing={2}>
                {shipmentList.map((shipment) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={shipment.id}>
                        <ShipmentCard shipment={shipment} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

ShipmentCards.propTypes = {
    shipmentList: PropTypes.any
};
