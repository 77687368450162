import { createSlice } from '@reduxjs/toolkit';
import { axiosVonto, axiosCatalog, axiosPeople } from 'hooks/axiosInstance';
import axios from 'axios';
import { dispatch } from '../index';

const initialState = {
    customerData: [],
    carriersData: [],
    shippingTypeData: [],
    serviceTypeData: [],
    modalitiesData: [],
    quotesData: [],
    quoteData: {},
    vendorsData: [],
    airportsData: [],
    seaportsData: [],
    containerTypeData: [],
    truckTypeData: [],
    pricingGroupsData: [],
    cargoStatusData: [],
    transportationTypeData: [],
    draftData: {},
    applyByData: [],
    unitsData: [],
    currencyData: [],
    costsData: []
};

const slice = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
        getCustomerSuccess(state, action) {
            state.customerData = action.payload;
        },
        getCarriersSuccess(state, action) {
            state.carriersData = action.payload;
        },
        getModalitiesSuccess(state, action) {
            state.modalitiesData = action.payload;
        },
        getShippingTypeSuccess(state, action) {
            state.shippingTypeData = action.payload;
        },
        getServiceTypeSuccess(state, action) {
            state.serviceTypeData = action.payload;
        },
        getDraftSuccess(state, action) {
            state.draftData = action.payload;
        },
        getVendorsSuccess(state, action) {
            state.vendorsData = action.payload;
        },
        getAirportSuccess(state, action) {
            state.airportsData = action.payload;
        },
        getSeaportSuccess(state, action) {
            state.seaportsData = action.payload;
        },
        getContainerTypeSuccess(state, action) {
            state.containerTypeData = action.payload;
        },
        getTruckTypeSuccess(state, action) {
            state.truckTypeData = action.payload;
        },
        getPricingGroupsSuccess(state, action) {
            state.pricingGroupsData = action.payload;
        },
        getCargoStatusSuccess(state, action) {
            state.cargoStatusData = action.payload;
        },
        getTransportationTypeSuccess(state, action) {
            state.transportationTypeData = action.payload;
        },
        getQuotesSuccess(state, action) {
            state.quotesData = action.payload;
        },
        getQuoteSuccess(state, action) {
            state.quoteData = action.payload;
        },
        getApplyBySuccess(state, action) {
            state.applyByData = action.payload;
        },
        getUnitsSuccess(state, action) {
            state.unitsData = action.payload;
        },
        getCurrencySuccess(state, action) {
            state.currencyData = action.payload;
        },
        getCostsSuccess(state, action) {
            state.costsData = action.payload;
        }
    }
});

export default slice.reducer;

export function getCustomers(filter) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/Organizations?Type=Customer&Filter=${filter}`);
            dispatch(slice.actions.getCustomerSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCarriers() {
    return async () => {
        try {
            const response = await axiosPeople.instance.get('/api/Organizations?Type=Carrier');
            dispatch(slice.actions.getCarriersSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getModalities() {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/shipping/ShippingModalities');
            // console.log(response);
            dispatch(slice.actions.getModalitiesSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getQuotes() {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/shipping/quotes');
            dispatch(slice.actions.getQuotesSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getQuote(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/quotes/${id}`);
            dispatch(slice.actions.getQuoteSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postInitialDraft(callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post('/api/shipping/quotes/drafts');
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getVendors() {
    return async () => {
        try {
            const response = await axiosVonto.instance.get();
            dispatch(slice.actions.getVendorsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getAirports(name) {
    return async () => {
        try {
            const response = await axios.get(`https://port-api.com/airport/search/${name}`);
            // console.log(response);
            dispatch(slice.actions.getAirportSuccess(response.data.features));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getSeaports(name) {
    return async () => {
        try {
            const response = await axios.get(`https://port-api.com/seaport/suggest/${name}`);
            dispatch(slice.actions.getSeaportSuccess(response.data.features));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getContainerTypes() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63cadd2a3c1a52155747eeee');
            // console.log(response);
            dispatch(slice.actions.getContainerTypeSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getTruckTypes() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63cadd2a3c1a52155747eeee');
            dispatch(slice.actions.getTruckTypeSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getPricingGroups() {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/employees/Groups?Department=Pricing');
            dispatch(slice.actions.getPricingGroupsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCargoStatus() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63bd914942ae70911c1ee4fd');
            dispatch(slice.actions.getCargoStatusSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getTransportationTypes() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63bd933542ae70911c1ee503');
            dispatch(slice.actions.getTransportationTypeSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function updateDraft(id, values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.put(`/api/shipping/quotes/drafts/${id}`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function generateQuote(draftId, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.put(`/api/shipping/quotes/drafts/generate-quote/${draftId}`);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function setPricingGroup(draftId, gId, callback) {
    const body = { groupId: gId };
    return async () => {
        try {
            const response = await axiosVonto.instance.put(`/api/shipping/quotes/draft/${draftId}/assign-pricing-group`, body);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getDraft(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/quotes/drafts/${id}`);
            dispatch(slice.actions.getDraftSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getApplyBy() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63cae1ff3c1a52155747eef5');
            dispatch(slice.actions.getApplyBySuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getUnits() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63cae20d3c1a52155747eef6');
            dispatch(slice.actions.getUnitsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCurrency() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/63cae21e3c1a52155747eef7');
            dispatch(slice.actions.getCurrencySuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getQuoteCosts(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/shipping/Charges/shipment/${id}/costs`);
            dispatch(slice.actions.getCostsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function addCosts(id, data, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post(`/api/shipping/Charges/shipment/${id}/costs/add`, data);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function updateCosts(quoteId, values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post(`/api/shipping/Charges/shipment/${quoteId}/costs/update`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function addIncome(quoteId, values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.put(`/api/shipping/Charges/shipment/${quoteId}/costs/add-income`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function setQuoteStatus(quoteId, quoteStatus, callback) {
    const value = { status: quoteStatus };
    return async () => {
        try {
            const response = await axiosVonto.instance.patch(`/api/shipping/quotes/status/${quoteId}`, value);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
