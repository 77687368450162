// react
import { useState, useMemo } from 'react';
import { useSelector } from 'store';
// material-ui
import { Box, Grid, CircularProgress, Stack, Hidden } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import ViewsBox from 'ui-component/components/ViewsBox';
import FilterBox from './components/FilterBox';
// import ExportBox from 'ui-component/components/ExportBox';
import SearchBox from 'ui-component/components/SearchBox';
// views
import ShipmentCards from './views/cards-view';
import ShipmentList from './views/list-view';
import Kpis from './views/kpis';

export default function ShipmentsPage() {
    const theme = useTheme();
    const [view, setView] = useState('grid'); // grid, list
    const { shipments } = useSelector((state) => state.shipments);
    console.log('all shipments (index)', shipments);

    // current search term
    const [currentSearch, setCurrentSearch] = useState('');
    const onSubmitSearch = (searchInput) => {
        setCurrentSearch(searchInput);
    };
    // Filtered shipment list based on filter and search
    const filteredShipments = useMemo(
        () => shipments.filter((shipment) => shipment.externalId.toLowerCase().includes(currentSearch.toLowerCase())),
        [currentSearch, shipments]
    );

    return (
        <Box>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="space-evenly"
                sx={{
                    position: 'sticky',
                    width: '102%',
                    top: '70px',
                    marginBottom: 2,
                    // paddingRight: '1px',
                    paddingBottom: '10px',
                    paddingTop: '5px',
                    borderRadius: '10px',
                    backgroundColor: theme.palette.primary.light,
                    zIndex: '100'
                }}
            >
                <Grid item xs={5} md={8} lg={9}>
                    <SearchBox onSubmitSearch={onSubmitSearch} />
                </Grid>
                <Grid item xs={1.5} md={2} lg={1}>
                    <FilterBox />
                </Grid>
                {/* <Grid item xs={1.5} md={2} lg={1}>
                    <ExportBox />
                </Grid> */}
                <Grid item xs={2} md={2} lg={1} xl={1}>
                    <ViewsBox view={view} setView={setView} link="new-shipment" linkMsg="Create New Shipment" />
                </Grid>
            </Grid>

            {/* Views rendering depending on the current view state */}
            {shipments.length === 0 && (
                <Stack justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Stack>
            )}
            {shipments.length !== 0 && (
                <Hidden only={['xs', 'sm']}>
                    <Kpis shipmentList={filteredShipments} />
                </Hidden>
            )}
            {view === 'grid' && <ShipmentCards shipmentList={filteredShipments} />}
            {view === 'list' && <ShipmentList shipmentList={filteredShipments} />}
        </Box>
    );
}
