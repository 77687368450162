// assets
import {
    IconUserCircle,
    IconLayoutGrid,
    IconBuilding,
    IconBasket,
    IconReportMoney,
    IconChecklist,
    IconTruckDelivery,
    IconFileInvoice,
    IconFileImport,
    IconFishHook,
    IconPackage,
    IconPackages,
    IconFileBarcode
} from '@tabler/icons';

// constant
const icons = {
    IconUserCircle,
    IconLayoutGrid,
    IconBuilding,
    IconBasket,
    IconReportMoney,
    IconChecklist,
    IconTruckDelivery,
    IconFileInvoice,
    IconFileImport,
    IconFishHook,
    IconPackage,
    IconPackages,
    IconFileBarcode
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'nav-sections',
    type: 'group',
    children: [
        {
            id: 'employees',
            title: 'Employees',
            type: 'item',
            url: '/employees',
            icon: icons.IconUserCircle,
            breadcrumbs: true,
            rolesGuard: ['']
        },
        {
            id: 'groups',
            title: 'Groups',
            type: 'item',
            url: '/groups',
            icon: icons.IconLayoutGrid,
            breadcrumbs: true,
            rolesGuard: ['']
        },
        {
            id: 'organizations',
            title: 'Organizations',
            type: 'item',
            url: '/organizations',
            icon: icons.IconBuilding,
            breadcrumbs: true,
            rolesGuard: ['Owner', 'Employee']
        },
        {
            id: 'shipments',
            title: 'Shipments',
            type: 'item',
            url: '/shipments',
            icon: icons.IconTruckDelivery,
            breadcrumbs: true,
            rolesGuard: ['Owner', 'Employee', 'Customer', 'ShipmentsOnly', 'ShipmentAndParcels']
        },
        {
            id: 'quotes',
            title: 'Quotes',
            type: 'item',
            url: '/quotes',
            icon: icons.IconReportMoney,
            breadcrumbs: true,
            rolesGuard: ['']
        },
        {
            id: 'invoices',
            title: 'Invoices',
            type: 'item',
            url: '/invoices',
            icon: icons.IconFileInvoice,
            breadcrumbs: true,
            rolesGuard: ['']
        },
        {
            id: 'leads',
            title: 'Leads',
            type: 'item',
            url: '/leads',
            icon: icons.IconFishHook,
            breadcrumbs: true,
            rolesGuard: ['']
        },
        {
            id: 'parcels',
            title: 'Parcels',
            type: 'collapse',
            icon: icons.IconPackage,
            rolesGuard: ['Owner', 'ParcelMaker', 'ShipmentAndParcels'],
            children: [
                {
                    id: 'parcelList',
                    title: 'All Parcels',
                    type: 'item',
                    breadcrumbs: true,
                    url: '/parcels',
                    rolesGuard: ['Owner', 'Customer', 'ParcelMaker', 'ShipmentAndParcels']
                },
                {
                    id: 'createParcel',
                    title: 'Create Parcel',
                    type: 'item',
                    breadcrumbs: true,
                    url: '/parcels/newParcel',
                    rolesGuard: ['Owner', 'Customer', 'ParcelMaker', 'ShipmentAndParcels']
                },
                {
                    id: 'parcelAddresses',
                    title: 'Addresses',
                    type: 'item',
                    breadcrumbs: true,
                    url: '/parcels/Addresses',
                    rolesGuard: ['Owner', 'Customer', 'ParcelMaker', 'ShipmentAndParcels']
                }
            ]
        },
        {
            id: 'parcelsAdmin',
            title: 'Parcels Admin',
            type: 'collapse',
            icon: icons.IconPackages,
            rolesGuard: ['ParcelMaker'],
            children: [
                {
                    id: 'parcelsAdminList',
                    title: 'All Parcels',
                    type: 'item',
                    breadcrumbs: true,
                    url: '/parcelsAdmin',
                    rolesGuard: ['ParcelMaker']
                },
                {
                    id: 'parcelsAdminReports',
                    title: 'All Reports',
                    type: 'item',
                    breadcrumbs: true,
                    url: '/parcelsAdmin/reports',
                    rolesGuard: ['ParcelMaker']
                }
            ]
        },
        {
            id: 'pulpo',
            title: 'Pulpo Parcels',
            type: 'item',
            url: '/pulpoParcels',
            icon: icons.IconFileBarcode,
            breadcrumbs: true,
            rolesGuard: ['ParcelMaker', 'Owner', 'Admin']
        }
    ]
};

export default other;
