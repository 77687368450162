import { Outlet } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ==============================|| MINIMAL LAYOUT ||============================== //

function MinimalLayout() {
    const theme = useTheme();

    return (
        <Box sx={{ ...theme.typography.loginContent }}>
            <CssBaseline />
            <Outlet />
        </Box>
    );
}

export default MinimalLayout;
