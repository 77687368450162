// material-ui
import { Typography, Grid, Stack, Skeleton } from '@mui/material';
// components

// utils
import { useSelector } from 'react-redux';
import InfoItem from 'ui-component/components/InfoItem';
import PersonCard from 'ui-component/components/PersonCard';

const styles = {
    title: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#0A0A0A'
    }
};

export default function ShipperScheduleSection() {
    const { shipmentById: shipment, isShipmentByIdLoading: loading } = useSelector((state) => state.shipments);

    // console.log('shippersectinfo', shipment);

    return (
        <Grid container>
            {/* Shipment Information */}
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    borderBottom: {
                        xs: '1px solid rgba(217, 224, 233, 0.7)',
                        sm: 'none'
                    },
                    paddingBottom: {
                        xs: '31px',
                        sm: '0px'
                    },
                    paddingLeft: {
                        xs: '0px',
                        sm: '31px'
                    },
                    marginInline: {
                        xs: '31px',
                        sm: '0px'
                    }
                }}
            >
                {loading ? (
                    <>
                        <Stack spacing="24px" sx={{ marginBottom: '32px' }}>
                            <Typography sx={styles.title}>Shipper</Typography>
                            <Skeleton animation="wave" height={10} width="25%" />
                        </Stack>

                        <Stack spacing="24px">
                            <Typography sx={styles.title}>Consignee</Typography>
                            <Skeleton animation="wave" height={10} width="25%" />
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack spacing="24px" sx={{ marginBottom: '32px' }}>
                            <Typography sx={styles.title}>Shipper</Typography>
                            <PersonCard name={shipment.shipper.name ?? ''} address={shipment.shipper.address.address1} />
                        </Stack>

                        <Stack spacing="24px">
                            <Typography sx={styles.title}>Consignee</Typography>
                            <PersonCard name={shipment.consignee.name ?? ''} address={shipment.consignee.address.address1} />
                        </Stack>
                    </>
                )}
            </Grid>
            {/* Schedule Information */}
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    borderLeft: {
                        xs: 'none',
                        sm: '1px solid rgba(217, 224, 233, 0.7)'
                    },

                    paddingLeft: {
                        xs: '31px',
                        sm: '31px'
                    },
                    paddingTop: {
                        xs: '31px',
                        sm: '0px'
                    }
                }}
            >
                <Typography
                    sx={[
                        styles.title,
                        {
                            marginBottom: '24px'
                        }
                    ]}
                >
                    Commodity
                </Typography>
                <Grid
                    container
                    rowSpacing="24px"
                    columnSpacing="24px"
                    sx={{
                        paddingRight: {
                            xs: '31px',
                            sm: '0px'
                        }
                    }}
                >
                    <InfoItem label="Volumetric Weight" data={`${shipment.volume?.Value} kg`} />
                    <InfoItem label="Weight" data={`${shipment.grossWeight?.Value} ${shipment.grossWeight?.Unit}`} />
                    <InfoItem label="CBM (Volume)" data={` ${shipment.volume?.Value} ${shipment.volume?.Unit}`} />
                    <InfoItem label="Chargable Weight" data={`${shipment.chargeableWeight} kg`} />
                    <InfoItem label="Value Of Goods" data={`${shipment.valueOfGoods?.Value} ${shipment.valueOfGoods?.Currency}`} />
                </Grid>
            </Grid>
        </Grid>
    );
}
