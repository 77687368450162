export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 4,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export const API_URL = {
    vontoLink: process.env.REACT_APP_VONTOLINK_API_URL,
    userAccess: process.env.REACT_APP_USER_ACCESS_API_URL,
    people: process.env.REACT_APP_PEOPLE_API_URL,
    catalogs: process.env.REACT_APP_CATALOGS_API_URL,
    parcels: process.env.REACT_APP_PARCELS_URL
};
export const parcelsApiKey = process.env.REACT_APP_PARCELS_AIRTABLE_KEY;
// export const parcelsKey = process.env.REACT_APP_PARCELS_APIKEY;

export default config;
