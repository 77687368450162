import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// material-ui
import { Card, Stack, Typography, Divider, Tooltip } from '@mui/material';
import { GpsFixed, Lock, Thermostat, Visibility } from '@mui/icons-material';
import PropTypes from 'prop-types';
// components
import containerImage from '../../../../assets/images/container.png';
import palleteImage from '../../../../assets/images/VontoCards_LCL-LTL.svg';
import truckImage from '../../../../assets/images/VontoCards_FTL.svg';
import DeliveryStageProgress from './DeliveryStageProgress';
// dependancies
import PersonSquare from 'ui-component/components/PersonSquare';
import StatusLabel from 'views/shipments/components/StatusLabel';
import { SHIPMENT_MODALITIES } from '../../../../constants';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

const styles = {
    label: {
        color: '#0A0A0A',
        fontSize: '12px',
        fontWeight: '500',
        opacity: '0.5'
    },
    valueBig: {
        color: '#0A0A0A',
        fontSize: '16px',
        fontWeight: '500'
    },
    valueSmall: {
        color: '#0A0A0A',
        fontSize: '11px',
        fontWeight: '500'
    },
    divider: {
        borderColor: '#D9D9D9',
        mx: 0.5
    }
};

const shipmentStatus = {
    CREATED: '#145DF2',
    'IN TRANSIT': '#F9C844',
    DELIVERED: '#01C5BA'
};
const modColor = {
    AIR: '#97BCF7',
    SEA: '#1457F5',
    ROAD: '#F5AA20'
};

// Big static component for shipment card

export default function ShipmentCard({ shipment }) {
    // console.log('card shipment', shipment);
    const [mod, setMod] = useState('');
    const [countryOrigin, setCountryOrigin] = useState('');
    const [countryDest, setCountryDest] = useState('');

    const getCountryCode = (str) => {
        const newString = str.split(',')[0];
        return newString;
    };

    useEffect(() => {
        if (shipment) {
            setMod(shipment.modality);
        }
    }, [shipment]);

    useEffect(() => {
        if (shipment.origin) {
            setCountryOrigin(getCountryCode(shipment.origin));
        }
    }, [shipment]);

    useEffect(() => {
        if (shipment.destination) {
            setCountryDest(getCountryCode(shipment.destination));
        }
    }, [shipment]);

    return (
        <Card
            sx={{
                padding: '16px 20px',
                '&:hover': {
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                },
                '&:hover #VisibilityIcon': {
                    color: '#145DF2',
                    scale: '1.2'
                }
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing="5px">
                    <Typography sx={styles.label}>Shipment Number</Typography>
                    <Typography sx={styles.valueBig}>{shipment.externalId}</Typography>
                </Stack>
                <Stack direction="row" spacing="10px">
                    <Link
                        to={`/shipments/${shipment.id}`}
                        style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Tooltip title="See More" placement="left">
                            <Visibility
                                id="VisibilityIcon"
                                sx={{
                                    color: '#E0E0E0',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                    transition: 'color 0.3s'
                                }}
                            />
                        </Tooltip>
                    </Link>
                    <StatusLabel value={shipment?.status} options={shipmentStatus} delayed={shipment.delayed} />
                </Stack>
            </Stack>

            <Divider
                sx={[
                    styles.divider,
                    {
                        marginBlock: '16px'
                    }
                ]}
            />
            <Stack direction="column" spacing="8px">
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column" spacing="5px">
                        <Typography sx={styles.label}>Origin</Typography>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            {countryOrigin && <Typography>{getUnicodeFlagIcon(countryOrigin)}</Typography>}
                            <Typography sx={styles.valueBig}>{shipment.origin}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing="5px">
                        <Typography
                            sx={[
                                styles.label,
                                {
                                    textAlign: 'end'
                                }
                            ]}
                        >
                            Destination
                        </Typography>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography
                                sx={[
                                    styles.valueBig,
                                    {
                                        textAlign: 'end'
                                    }
                                ]}
                            >
                                {shipment.destination}
                            </Typography>
                            {countryDest && <Typography>{getUnicodeFlagIcon(countryDest)}</Typography>}
                        </Stack>
                    </Stack>
                </Stack>

                <DeliveryStageProgress status={shipment.status} />
            </Stack>

            <Divider
                sx={[
                    styles.divider,
                    {
                        marginBlock: '16px'
                    }
                ]}
            />

            <Stack direction="row" sx={{ maxWidth: '100%', overflow: 'hidden' }} justifyContent="space-between">
                <Stack direction="row" sx={{ maxWidth: '45%' }} spacing={1} justifyContent="flex-start">
                    <PersonSquare />
                    {/* <Tooltip title="Vendor Details">
                        <Link disabled to={`/vendors/${shipment.shipper.id}`}>
                            <PersonSquare />
                        </Link>
                    </Tooltip> */}
                    <Tooltip title={shipment.shipper.name} placement="bottom-start">
                        <Stack sx={{ cursor: 'default' }} direction="column" spacing={1} justifyContent="center">
                            <Typography sx={{ ...styles.label }}>Shipper</Typography>
                            <Typography
                                sx={{
                                    ...styles.valueSmall,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    height: '30%',
                                    maxWidth: '10ch',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {shipment.shipper.name}
                            </Typography>
                        </Stack>
                    </Tooltip>
                </Stack>
                <Divider flexItem orientation="vertical" sx={styles.divider} />
                <Stack sx={{ width: '45%' }} direction="row" spacing="10px">
                    <PersonSquare />
                    {/* <Tooltip title="Vendor Details">
                        <Link to={`/vendors/${shipment.consignee.id}`}>
                            <PersonSquare />
                        </Link>
                    </Tooltip> */}
                    <Tooltip title={shipment.consignee.name} placement="bottom-start">
                        <Stack sx={{ cursor: 'default' }} direction="column" spacing="5px" justifyContent="center">
                            <Typography sx={{ ...styles.label }}>Consignee</Typography>
                            <Typography
                                sx={{
                                    ...styles.valueSmall,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    height: '30%',
                                    maxWidth: '10ch',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {shipment.consignee.name}
                            </Typography>
                        </Stack>
                    </Tooltip>
                </Stack>
            </Stack>

            <Divider
                sx={[
                    styles.divider,
                    {
                        marginBlock: '16px'
                    }
                ]}
            />

            <Stack direction="column" spacing="10px">
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={styles.label}>Transport:</Typography>
                    <Typography
                        sx={{
                            color: '#FAFAFA',
                            fontSize: '10px',
                            backgroundColor: modColor[mod],
                            padding: '2px 16px',
                            fontWeight: '700',
                            textAlign: 'center',
                            borderRadius: '4px'
                        }}
                    >
                        {mod}
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={styles.label}>Container:</Typography>
                    <Typography sx={styles.valueSmall}>{shipment.shipmentContainerType}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={styles.label}>ATA</Typography>
                    <Typography sx={styles.valueSmall}>{shipment.actualTimeArrival || '-'}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography sx={styles.label}>Estimated departure:</Typography>
                    <Typography sx={styles.valueSmall}>{shipment.estimatedTimeOfDeparture}</Typography>
                </Stack>
                {shipment.delayed?.status === false ? (
                    <Stack direction="row" justifyContent="space-between">
                        <Typography sx={styles.label}>Estimated arrival:</Typography>
                        <Typography sx={styles.valueSmall}>{shipment.estimatedTimeOfArrival}</Typography>
                    </Stack>
                ) : (
                    <>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={styles.label}>Estimated arrival:</Typography>
                            <Typography sx={{ ...styles.valueSmall }}>{shipment.estimatedTimeOfArrival}</Typography>
                        </Stack>
                    </>
                )}
                {shipment.modality === 'SEA' && (
                    <>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={styles.label}>Voyage</Typography>
                            <Typography sx={styles.valueSmall}>{shipment.individualModalityInfo.Voyage || '-'}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={styles.label}>Vessel Name</Typography>
                            <Typography sx={styles.valueSmall}>{shipment.individualModalityInfo.VesselName || '-'}</Typography>
                        </Stack>
                    </>
                )}
                {shipment.modality === 'AIR' && (
                    <>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={styles.label}>Flight Number</Typography>
                            <Typography sx={styles.valueSmall}>{shipment.individualModalityInfo.FlightNumber || '-'}</Typography>
                        </Stack>
                    </>
                )}
            </Stack>

            <Divider
                sx={[
                    styles.divider,
                    {
                        marginBlock: '16px'
                    }
                ]}
            />

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing="5px">
                    <Typography sx={styles.label}>Package type:</Typography>
                    <Stack direction="row" spacing={0.5}>
                        <Typography component="span" sx={[styles.valueSmall, { color: '#145DF2' }]}>
                            {shipment.package.Quantity ?? 0}
                        </Typography>
                        <Typography sx={styles.valueSmall}>x</Typography>
                        <Typography sx={styles.valueSmall}>{shipment.package?.Type}</Typography>
                    </Stack>
                </Stack>
                {shipment.modality === SHIPMENT_MODALITIES.sea && <img height={80} width={150} src={containerImage} alt="shipment" />}
                {shipment.modality === SHIPMENT_MODALITIES.air && <img height={80} width={150} src={palleteImage} alt="shipment" />}
                {shipment.modality === SHIPMENT_MODALITIES.road && <img height={80} width={150} src={truckImage} alt="shipment" />}
            </Stack>

            <Divider sx={[styles.divider, { marginBlock: '16px' }]} />

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing="5px">
                    <GpsFixed
                        sx={{
                            opacity: 0.5
                        }}
                    />
                    <Typography sx={[styles.valueSmall, { opacity: 0.5 }]}>Gps Enabled</Typography>
                </Stack>

                <Stack direction="row" spacing="0px" alignItems="center">
                    <Thermostat
                        sx={{
                            opacity: 0.5
                        }}
                    />
                    <Typography sx={[styles.valueSmall, { opacity: 0.5, fontWeight: 'bold' }]}>20°C</Typography>
                </Stack>
                <Stack direction="row" spacing="5px" alignItems="center">
                    <Lock
                        sx={{
                            opacity: 0.5
                        }}
                    />
                    <Typography sx={[styles.valueSmall, { opacity: 0.5 }]}>Locked</Typography>
                </Stack>
            </Stack>
        </Card>
    );
}

ShipmentCard.propTypes = {
    shipment: PropTypes.any
};
