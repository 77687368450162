// import { ChevronLeft } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import StatusLabel from 'views/shipments/components/StatusLabel';
import ViewSwitch from 'views/shipments/components/ViewsSwitch';
import DetailedStatusView from 'views/shipments/views/detailed-status-view';
import InformationView from 'views/shipments/views/information-view';
import InvoiceView from 'views/shipments/views/InvoiceView';
import truckImage from '../../../../assets/images/truck.png';
import containerImage from '../../../../assets/images/container.png';
import palleteImage from '../../../../assets/images/VontoCards_LCL-LTL.svg';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { getShipmentById, makeEmptyShipment, setIsShipmentByIdLoading } from 'store/slices/shipments';
import { objectIsEmpty } from 'utils/functions';

const styles = {
    divider: {
        borderColor: '#D9D9D9'
    }
};

const shipmentStatusLabels = {
    CREATED: '#145DF2',
    'IN TRANSIT': '#F9C844',
    DELIVERED: '#01C5BA'
};

const ShipmentDetails = () => {
    const { id } = useParams();
    const { shipmentById: shipment } = useSelector((state) => state.shipments);
    const [currentView, setCurrentView] = useState('Information'); // Information, DetailedStatus
    const [shipmentStatus, setShipmentStatus] = useState('');

    useEffect(() => {
        dispatch(getShipmentById(id));

        return () => {
            dispatch(makeEmptyShipment());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!objectIsEmpty(shipment)) {
            dispatch(setIsShipmentByIdLoading(false));
            const status = shipment.statusHistory[shipment.statusHistory.length - 1]?.phase;
            setShipmentStatus(status);
        }
        return () => {
            dispatch(setIsShipmentByIdLoading(true));
        };
    }, [shipment]);

    console.log('Shipment', shipment);

    return (
        <Box>
            {/* Header */}

            <Stack direction="row" alignItems="center" spacing="20px">
                <Stack
                    direction="row"
                    spacing={{
                        xs: '0px',
                        sm: '20px'
                    }}
                    sx={{
                        flexWrap: 'wrap'
                    }}
                >
                    <Stack direction="column" spacing="12px" alignItems="start">
                        <Stack direction="row" alignItems="center">
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#0A0A0A',
                                    opacity: '0.5'
                                }}
                            >
                                Shipment Number
                            </Typography>
                        </Stack>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                color: '#0A0A0A',
                                marginBottom: '8px'
                            }}
                        >
                            {shipment.externalId}
                        </Typography>
                    </Stack>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={[
                            styles.divider,
                            {
                                marginInline: '16px',
                                display: {
                                    xs: 'none',
                                    sm: 'block'
                                }
                            }
                        ]}
                    />
                    <Stack
                        direction="column"
                        alignItems="start"
                        spacing="12px"
                        sx={{
                            marginTop: {
                                xs: '25px!important',
                                sm: '0px!important'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#0A0A0A',
                                opacity: '0.5'
                            }}
                        >
                            Customer Reference Number
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '600',
                                color: '#0A0A0A',
                                marginBottom: '8px'
                            }}
                        >
                            {shipment.customerReferenceNumber}
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            alignSelf: 'end',
                            marginTop: {
                                xs: '25px!important',
                                sm: '0px!important'
                            },
                            marginLeft: {
                                xs: 'auto!important',
                                sm: '12px!important',
                                md: '12px!important'
                            }
                        }}
                    >
                        <StatusLabel value={shipment.currentStatus} delayed={shipment.delayed} options={shipmentStatusLabels}>
                            {shipmentStatus}
                        </StatusLabel>
                    </Box>
                </Stack>

                <Box
                    sx={{
                        width: '225px',
                        height: '95px',
                        marginLeft: 'auto!important',
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex'
                        },
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {/* <img src={truckImage} alt="truck" style={{ width: '100%', height: '100%' }} /> */}
                    {shipment.modality === 'OCEAN' && <img height="100%" width="100%" src={containerImage} alt="shipment" />}
                    {shipment.modality === 'AIR' && <img height="100%" width="100%" src={palleteImage} alt="shipment" />}
                    {shipment.modality === 'LAND' && <img height="100%" width="100%" src={truckImage} alt="shipment" />}
                </Box>
            </Stack>
            <Divider sx={{ border: 'none', marginBottom: '36px' }} />

            {/* Views switch */}
            <ViewSwitch setCurrentView={setCurrentView} currentView={currentView} />

            <Divider sx={{ border: 'none', marginBottom: '33px' }} />
            {/* View */}
            {currentView === 'Information' && <InformationView />}
            {currentView === 'DetailedStatus' && <DetailedStatusView />}
            {currentView === 'Invoice' && <InvoiceView />}
        </Box>
    );
};

export default ShipmentDetails;
