import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //
function hasRole(menuItem, role) {
    return !menuItem.rolesGuard || (menuItem.rolesGuard && menuItem.rolesGuard.includes(role));
}

function filterMenu(menuItems, role) {
    const items = [];
    menuItems.forEach((item) => {
        if ((item.type === 'group' || item.type === 'collapse') && hasRole(item, role)) {
            item.children = filterMenu(item.children, role);
            items.push(item);
        } else if (hasRole(item, role)) {
            items.push(item);
        }
    });
    return items;
}

const MenuList = () => {
    const { user } = useAuth();
    // console.log(user.role);
    const filteredMenuItems = filterMenu(menuItem.items, user.role);
    const navItems = filteredMenuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
