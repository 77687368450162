// material-ui
import { Stack, Typography, Box, Grid, Skeleton } from '@mui/material';
// assets
import arrowRight from '../../../../assets/images/arrowRight.png';
import { useSelector } from 'react-redux';

const styles = {
    label: {
        fontSize: {
            xs: '14px',
            sm: '16px'
        },
        fontWeight: '400',
        color: '#0A0A0A',
        opacity: '0.5'
    },
    valueBig: {
        fontSize: {
            xs: '18px',
            sm: '20px'
        },
        fontWeight: '500',
        color: '#0A0A0A'
    },
    valueSmall: {
        fontSize: {
            xs: '16px',
            sm: '16px'
        },
        fontWeight: '400',
        color: '#0A0A0A'
    }
};

export default function OriginDeliverySection() {
    const { shipmentById: shipment, isShipmentByIdLoading: loading } = useSelector((state) => state.shipments);
    return (
        <Grid
            container
            spacing={2}
            sx={{
                paddingInline: '31px',
                width: '100%',
                marginInline: 'auto'
            }}
        >
            <Grid item xs={12} md={4.5}>
                <Stack
                    direction="row"
                    spacing="38px"
                    justifyContent={{
                        xs: 'center',
                        sm: 'center',
                        md: 'flex-start'
                    }}
                >
                    {loading ? (
                        <>
                            <Stack spacing="12px">
                                <Skeleton animation="wave" height={10} width="25%" />
                                <Skeleton animation="wave" height={20} width="80%" />
                            </Stack>
                            <Stack spacing="15px">
                                <Skeleton animation="wave" height={10} width="25%" />
                                <Skeleton animation="wave" height={20} width="80%" />
                            </Stack>
                        </>
                    ) : (
                        <>
                            <Stack direction="column" spacing={2} alignContent="center" justifyContent="flex-start">
                                <Typography sx={{ textAlign: 'center' }} variant="h3">
                                    Origin
                                </Typography>
                                <Stack direction="row" spacing={2} justifyContent="space-between">
                                    <Stack spacing="12px">
                                        <Typography sx={styles.label}>Country</Typography>
                                        <Typography sx={styles.valueSmall}>{shipment.origin.Country}</Typography>
                                    </Stack>
                                    <Stack spacing="15px">
                                        <Typography sx={{ ...styles.label, textAlign: 'center' }}>State</Typography>
                                        <Typography sx={styles.valueSmall}>{shipment.origin?.State}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex'
                        },
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <img src={arrowRight} alt="arrow" />
                </Box>
            </Grid>
            <Grid item xs={12} md={4.5}>
                <Stack
                    direction="row"
                    spacing="38px"
                    justifyContent={{
                        xs: 'center',
                        sm: 'center',
                        md: 'flex-end'
                    }}
                    sx={{
                        width: '100%'
                    }}
                >
                    {loading ? (
                        <>
                            <Stack spacing="12px">
                                <Skeleton animation="wave" height={10} width="25%" />
                                <Skeleton animation="wave" height={20} width="80%" />
                            </Stack>
                            <Stack spacing="15px">
                                <Skeleton animation="wave" height={10} width="25%" />
                                <Skeleton animation="wave" height={20} width="80%" />
                            </Stack>
                        </>
                    ) : (
                        <Stack direction="column" spacing={2} alignContent="center" justifyContent="flex-start">
                            <Typography sx={{ textAlign: 'center' }} variant="h3">
                                Destination
                            </Typography>
                            <Stack direction="row" spacing={2} justifyContent="space-between">
                                <Stack spacing="12px">
                                    <Typography sx={styles.label}>Country</Typography>
                                    <Typography sx={styles.valueSmall}>{shipment.destination.Country}</Typography>
                                </Stack>
                                <Stack spacing="15px">
                                    <Typography sx={{ ...styles.label, textAlign: 'center' }}>State</Typography>
                                    <Typography sx={styles.valueSmall}>{shipment.destination?.State}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}
