/* eslint-disable no-nested-ternary */
import { Typography, Stack, Tooltip } from '@mui/material';
import { Error } from '@mui/icons-material';
import PropTypes from 'prop-types';

export default function StatusLabel({ value, options, delayed }) {
    const backgroundColor = options[value];
    const style = {
        color: '#FAFAFA',
        fontSize: '16px',
        backgroundColor: delayed?.status && delayed?.status === true ? '#FF6174' : backgroundColor,
        padding: '5px 16px',
        fontWeight: '700',
        borderRadius: '8px'
    };

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing="6px">
            {delayed?.status && (
                <Tooltip
                    title={
                        <Stack direction="column" alignContent="center" alignItems="center" justifyContent="center">
                            <Typography sx={{ color: '#ffffff' }} variant="h4">
                                Delayed
                            </Typography>
                            <Typography>Details: {delayed.description}</Typography>
                            <Typography>Updated At: {delayed.updatedAt}</Typography>
                        </Stack>
                    }
                    placement="bottom"
                >
                    <Error sx={{ fill: '#FF6174' }} />
                </Tooltip>
            )}
            <Typography sx={style}>{value}</Typography>
        </Stack>
    );
}

StatusLabel.propTypes = {
    value: PropTypes.any,
    options: PropTypes.object,
    delayed: PropTypes.object
};
