import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'hooks/RoleGuard';
import ShipmentsPage from 'views/shipments';
import ShipmentDetails from 'views/shipments/pages/shipmentDetails';
// routing
const Dashboard = Loadable(lazy(() => import('views/dashboard-Shipment/index')));
const Employees = Loadable(lazy(() => import('views/employees')));
const NewEmployee = Loadable(lazy(() => import('views/employees/components/newEmployee')));
const EmployeeDetails = Loadable(lazy(() => import('views/employees/pages/employeeDetails')));
const Groups = Loadable(lazy(() => import('views/groups')));
const GroupDetails = Loadable(lazy(() => import('views/groups/pages/GroupDetails')));
const UserProfile = Loadable(lazy(() => import('views/userProfile/index')));
const Organizations = Loadable(lazy(() => import('views/organizations')));
const NewOrganization = Loadable(lazy(() => import('views/organizations/pages/NewOrganization/index')));
const OrganizationDetails = Loadable(lazy(() => import('views/organizations/pages/OrganizationDetails/index')));
const Management = Loadable(lazy(() => import('views/management')));
const Quotes = Loadable(lazy(() => import('views/quotes')));
const NewQuote = Loadable(lazy(() => import('views/quotes/pages/NewQuote/index')));
const ManageQuote = Loadable(lazy(() => import('views/quotes/pages/ManageQuotes/index')));
const ManageQuoteCustomer = Loadable(lazy(() => import('views/quotes/pages/ManageQuotesCustomer/index')));
const MyOrganization = Loadable(lazy(() => import('views/organizations/MyOrganization/index')));
const NewShipment = Loadable(lazy(() => import('views/shipments/pages/NewShipment/index')));
const VendorDetails = Loadable(lazy(() => import('views/shipments/pages/VendorDetails/index')));
const TestPage = Loadable(lazy(() => import('views/AAtesting/testing')));
const Invoice = Loadable(lazy(() => import('views/invoice/index')));
const Leads = Loadable(lazy(() => import('views/leads/index')));
const Parcels = Loadable(lazy(() => import('views/parcels/index')));
const ParcelsForm = Loadable(lazy(() => import('views/parcels/components/NewParcelForm')));
const AddressList = Loadable(lazy(() => import('views/parcels/AddressIndex')));
const ParcelsAdmin = Loadable(lazy(() => import('views/parcelsAdmin/index')));
const PulpoParcels = Loadable(lazy(() => import('views/pulpoParcels/index')));
const PulpoRatesSelect = Loadable(lazy(() => import('views/pulpoParcels/components/SetParcelRate')));
// sample page
// const SamplePage = Loadable(lazy(() => import('views/pages/SamplePage'))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'employees',
            element: (
                <RoleGuard roles={['Owner', 'Employee']}>
                    <Employees />
                </RoleGuard>
            )
        },
        {
            path: 'employees/new-employee',
            element: (
                <RoleGuard roles={['Owner', 'Customer']}>
                    <NewEmployee />
                </RoleGuard>
            )
        },
        {
            path: 'employees/:id',
            element: (
                <RoleGuard roles={['Owner', 'Customer', 'Employee']}>
                    <EmployeeDetails />
                </RoleGuard>
            )
        },
        {
            path: 'groups',
            element: (
                <RoleGuard roles={['Owner', 'Customer', 'Employee']}>
                    <Groups />
                </RoleGuard>
            )
        },
        {
            path: 'groups/:id',
            element: (
                <RoleGuard roles={['Owner', 'Customer', 'Employee']}>
                    <GroupDetails />
                </RoleGuard>
            )
        },
        {
            path: '/profile',
            element: <UserProfile />
        },
        {
            path: '/organizations',
            element: (
                <RoleGuard roles={['Owner', 'Employee', 'Customer']}>
                    <Organizations />
                </RoleGuard>
            )
        },
        {
            path: 'organizations/new-organization',
            element: <NewOrganization />
        },
        {
            path: 'organizations/:id',
            element: <OrganizationDetails />
        },
        {
            path: '/management',
            element: <Management />
        },
        {
            path: '/quotes',
            element: <Quotes />
        },
        {
            path: '/quotes/new-quote',
            element: <NewQuote />
        },
        {
            path: '/quotes/:id-manage',
            element: <ManageQuote />
        },
        {
            path: '/shipments',
            element: (
                <RoleGuard roles={['Owner', 'ShipmentsOnly', 'ShipmentAndParcels']}>
                    <ShipmentsPage />
                </RoleGuard>
            )
        },
        {
            path: '/shipments/:id',
            element: (
                <RoleGuard roles={['Owner', 'ShipmentsOnly', 'ShipmentAndParcels']}>
                    <ShipmentDetails />
                </RoleGuard>
            )
        },
        {
            path: '/shipments/new-shipment',
            element: <NewShipment />
        },
        {
            path: '/quotes/:id-custManage',
            element: <ManageQuoteCustomer />
        },
        {
            path: '/my-organization',
            element: <MyOrganization />
        },
        {
            path: '/vendors/:id',
            element: <VendorDetails />
        },
        {
            path: '/test',
            element: <TestPage />
        },
        {
            path: '/invoices',
            element: (
                <RoleGuard roles={['Owner', 'Employee']}>
                    <Invoice />
                </RoleGuard>
            )
        },
        {
            path: '/leads',
            element: (
                <RoleGuard roles={['Owner']}>
                    <Leads />
                </RoleGuard>
            )
        },
        {
            path: '/parcels',
            element: (
                <RoleGuard roles={['ParcelMaker', 'ShipmentAndParcels', 'Owner']}>
                    <Parcels />
                </RoleGuard>
            )
        },
        {
            path: '/parcels/newParcel',
            element: (
                <RoleGuard roles={['ParcelMaker']}>
                    <ParcelsForm />
                </RoleGuard>
            )
        },
        {
            path: '/parcels/Addresses',
            element: (
                <RoleGuard roles={['ParcelMaker']}>
                    <AddressList />
                </RoleGuard>
            )
        },
        {
            path: '/parcelsAdmin',
            element: (
                <RoleGuard roles={['ParcelMaker']}>
                    <ParcelsAdmin />
                </RoleGuard>
            )
        },
        {
            path: '/pulpoParcels',
            element: (
                <RoleGuard roles={['ParcelMaker', 'Admin', 'Owner']}>
                    <PulpoParcels />
                </RoleGuard>
            )
        },
        {
            path: '/pulpoRatesSelect',
            element: (
                <RoleGuard roles={['ParcelMaker', 'Admin', 'Owner']}>
                    <PulpoRatesSelect />
                </RoleGuard>
            )
        }
    ]
};

export default MainRoutes;
