import { createSlice } from '@reduxjs/toolkit';

import { axiosUserAccess } from 'hooks/axiosInstance';
import { dispatch } from '../index';

const initialState = {
    error: null,
    groups: [],
    group: [],
    members: [],
    memberSelect: [],
    leadByDepartment: [],
    createNewGroup: []
};

const slice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getGroupsSuccess(state, action) {
            state.groups = action.payload;
        },
        getGroupDetailsSuccess(state, action) {
            state.group = action.payload;
        },
        getGroupMembersSucces(state, action) {
            state.members = action.payload;
        },
        getMemberOptionsList(state, action) {
            state.memberSelect = action.payload;
        },
        getLeaderDepartment(state, action) {
            state.leadByDepartment = action.payload;
        },
        postCreateGroup(state, action) {
            state.createNewGroup = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getGroups() {
    return async () => {
        try {
            const response = await axiosUserAccess.instance.get('/api/employees/groups');
            dispatch(slice.actions.getGroupsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGroupDetails(id) {
    return async () => {
        try {
            const response = await axiosUserAccess.instance.get(`/api/employees/groups/${id}`);
            dispatch(slice.actions.getGroupDetailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGroupMembers() {
    return async () => {
        try {
            const response = await axiosUserAccess.instance.get('');
            dispatch(slice.actions.getGroupMembersSucces(response.werever));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getMemberOptions(departmentValue) {
    const params = new URLSearchParams([
        ['Role', 'Executive'],
        ['Department', departmentValue],
        ['IsInGroup', false]
    ]);
    return async () => {
        try {
            const response = await axiosUserAccess.instance.get('/api/employees/employees', { params });
            dispatch(slice.actions.getMemberOptionsList(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getLeaderByDepartment(deparmentValue) {
    const params = new URLSearchParams([
        ['Role', 'Leader'],
        ['Department', deparmentValue],
        ['IsInGroup', false]
    ]);
    return async () => {
        try {
            const response = await axiosUserAccess.instance.get('/api/employees/employees', { params });
            dispatch(slice.actions.getLeaderDepartment(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postCreateNewGroup(values) {
    return async () => {
        try {
            const response = await axiosUserAccess.instance.post('api/employees/groups', values);
            dispatch(slice.actions.postCreateGroup(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function putEditGroups(groupData, values) {
    // console.log('edit: ', groupData, values);
    return async () => {
        try {
            const response = await axiosUserAccess.instance.put(`api/employees/groups/${groupData}`, values);
            if (response) {
                dispatch(getGroupDetails(groupData));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
