// material-ui
import { Typography, Grid, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import InfoItem from 'ui-component/components/InfoItem';
import { useState, useEffect } from 'react';
import { SHIPMENT_MODALITIES } from '../../../../constants';
// components

// styles
const styles = {
    title: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#0A0A0A'
    },
    label: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#0A0A0A',
        opacity: '0.5'
    },
    data: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#0A0A0A'
    }
};

const modColor = {
    AIR: '#97BCF7',
    OCEAN: '#1457F5',
    GROUND: '#F5AA20'
};

export default function ShipmentTripInformationSection() {
    const { shipmentById: shipment, isShipmentByIdLoading: loading } = useSelector((state) => state.shipments);
    const [mod, setMod] = useState('');
    // console.log('Shipment Info:', shipment);

    useEffect(() => {
        if (shipment) {
            setMod(shipment.modality);
        }
    }, [shipment]);

    return (
        <Grid container>
            {/* Shipment Information */}
            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    marginLeft: {
                        xs: '31px',
                        sm: '0px'
                    },
                    marginRight: {
                        xs: '31px',
                        sm: '0px'
                    },
                    paddingBottom: {
                        xs: '24px',
                        sm: '0px'
                    },
                    paddingLeft: {
                        xs: '0px',
                        sm: '31px'
                    },
                    paddingRight: {
                        xs: '0px',
                        sm: '31px'
                    },

                    borderBottom: {
                        xs: '1px solid rgba(217, 224, 233, 0.7)',
                        sm: 'none'
                    }
                }}
            >
                <Typography
                    sx={[
                        styles.title,
                        {
                            marginBottom: '24px'
                        }
                    ]}
                >
                    Shipment Information
                </Typography>
                <Grid container rowSpacing="24px" columnSpacing="15px">
                    {loading ? (
                        <>
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                        </>
                    ) : (
                        <>
                            <InfoItem label="Transport" data={mod} backgroundColor={modColor[mod]} />
                            <InfoItem label="Incoterm" data={shipment.incoterm?.DisplayName} backgroundColor="#7A2CD5" />
                            <InfoItem label="Container" data={shipment.containerType} backgroundColor="#87AEF4" />
                            <InfoItem label="Direction" data={shipment.direction ?? '-'} backgroundColor="#0FBAB8" />
                            <InfoItem label="Package" data={shipment.package?.Type} backgroundColor="#3F92D1" />
                            <InfoItem label="Quantity" data={shipment.package?.Quantity ?? 0} />
                            <InfoItem label="Service Level" data={shipment.operationType || '-'} backgroundColor="#000" />
                        </>
                    )}
                </Grid>
            </Grid>

            {/* Trip Information */}

            <Grid
                item
                xs={12}
                sm={6}
                sx={{
                    paddingLeft: '31px',
                    borderLeft: { sm: '1px solid rgba(217, 224, 233, 0.7)' },
                    marginTop: {
                        xs: '24px',
                        sm: '0px'
                    },
                    marginRight: {
                        xs: '31px',
                        sm: '0px'
                    },
                    paddingRight: {
                        xs: '0px',
                        sm: '31px'
                    }
                }}
            >
                <Typography
                    sx={[
                        styles.title,
                        {
                            marginBottom: '24px'
                        }
                    ]}
                >
                    Trip Information
                </Typography>

                <Grid container rowSpacing="24px" columnSpacing="15px">
                    {loading ? (
                        <>
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                            <Skeleton animation="wave" height={20} width="80%" />
                        </>
                    ) : (
                        <>
                            {mod === SHIPMENT_MODALITIES.air && (
                                <>
                                    <InfoItem label="AOL" data={shipment.ladingPort?.Unlocode || '-'} />
                                    <InfoItem label="AOD" data={shipment.finalPort?.Unlocode || '-'} />
                                    <InfoItem label="Carrier" data={shipment.mainCarrier?.name || '-'} />
                                    <InfoItem label="Flight Number" data={shipment.tripNumber || '-'} />
                                    <InfoItem label="AirWaybill Number" data={shipment.waybillNumber} />
                                    <InfoItem label="Bookin No." data={shipment.bookingNumber || '-'} />
                                    <InfoItem label="Estimated Departure" data={shipment.estimatedTimeOfDeparture || '-'} />
                                    <InfoItem label="Estimated Arrival" data={shipment.estimatedTimeOfArrival || '-'} />
                                </>
                            )}
                            {mod === SHIPMENT_MODALITIES.sea && (
                                <>
                                    <InfoItem label="POL" data={shipment.ladingPort.Unlocode || '-'} />
                                    <InfoItem label="POD" data={shipment.finalPort.Unlocode || '-'} />
                                    <InfoItem label="Carrier" data={shipment.mainCarrier?.name || '-'} />
                                    <InfoItem label="Vessel Name" data={shipment.vesselInformation?.name || '-'} />
                                    <InfoItem label="Voyage" data={shipment.vesselInformation?.voyage || '-'} />
                                    <InfoItem label="Bookin No." data={shipment.bookingNumber || '-'} />
                                    <InfoItem label="Waybill No." data={shipment.waybillNumber || '-'} />
                                    <InfoItem label="Estimated Departure" data={shipment.estimatedTimeOfDeparture || '-'} />
                                    <InfoItem label="Estimated Arrival" data={shipment.estimatedTimeOfArrival || '-'} />
                                </>
                            )}
                            {mod === SHIPMENT_MODALITIES.road && (
                                <>
                                    <InfoItem label="Departure From" data={shipment.ladingPort.Unlocode || '-'} />
                                    <InfoItem label="Arrive to" data={shipment.finalPort.Unlocode || '-'} />
                                    <InfoItem label="Carrier" data={shipment.mainCarrier?.name || '-'} />
                                    <InfoItem label="Operation Type" data={shipment?.operationType || '-'} />
                                    <InfoItem label="Bookin No." data={shipment.bookingNumber || '-'} />
                                    <InfoItem label="Estimated Departure" data={shipment.estimatedTimeOfDeparture || '-'} />
                                    <InfoItem label="Estimated Arrival" data={shipment.estimatedTimeOfArrival || '-'} />
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
