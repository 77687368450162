import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material-ui
import { LinearProgress, Box, Stack } from '@mui/material';
import { Circle, LocalShipping, TourRounded } from '@mui/icons-material';
import { SHIPMENT_STATUS } from '../../../../constants';

export default function DeliveryStageProgress({ status }) {
    const [statusValue, setStatusValue] = useState(0);
    // eslint-disable-next-line no-nested-ternary
    // const color = status === 'Created' ? '#145DF2' : status === 'InTransit' ? '#F9C844' : status === 'Delivered' && '#01C5BA';
    const color = '#145DF2';

    useEffect(() => {
        switch (status) {
            case SHIPMENT_STATUS.created:
                setStatusValue(0);
                break;
            case SHIPMENT_STATUS.inTransit:
                setStatusValue(50);
                break;
            case SHIPMENT_STATUS.delivered:
                setStatusValue(100);
                break;
            default:
        }
    }, [status]);

    return (
        <Stack
            sx={{
                width: '100%'
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Stack
                direction="column"
                sx={{
                    position: 'relative',
                    height: '27px',
                    width: '90%'
                }}
                justifyItems="center"
            >
                <LinearProgress
                    variant="buffer"
                    valueBuffer={0}
                    value={statusValue}
                    sx={{
                        height: '1px',
                        borderRadius: '4px',
                        marginTop: '12px',

                        '& .MuiLinearProgress-bar2Buffer': {
                            backgroundColor: 'transparent'
                        },
                        '& .MuiLinearProgress-bar1Buffer': {
                            backgroundColor: color
                        },
                        '& .MuiLinearProgress-dashed': {
                            border: 'dashed 1px #A8ABAF',
                            height: '1px',
                            backgroundImage: 'none',
                            animation: 'none'
                        }
                    }}
                />
                <Circle
                    sx={{
                        color,
                        position: 'absolute',
                        top: '0px',
                        left: '0%',
                        fontSize: '12px',
                        translate: '-50% 50%'
                    }}
                />
                <Circle
                    sx={{
                        color,
                        position: 'absolute',
                        top: '0px',
                        left: '50%',
                        fontSize: '12px',
                        translate: '-50% 50%'
                    }}
                />
                <Circle
                    sx={{
                        color,
                        position: 'absolute',
                        top: '0px',
                        left: '100%',
                        fontSize: '12px',
                        translate: '-50% 50%'
                    }}
                />
                <Box
                    sx={{
                        width: '27px',
                        height: '27px',
                        position: 'absolute',
                        top: '0px',
                        left: `${statusValue}%`,

                        backgroundColor: color,
                        translate: '-50% 0 ',
                        borderRadius: '999px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {' '}
                    {status === 100 ? (
                        <TourRounded
                            sx={{
                                color: 'white',

                                fontSize: '13px'
                            }}
                        />
                    ) : (
                        <LocalShipping
                            sx={{
                                color: 'white',

                                fontSize: '13px'
                            }}
                        />
                    )}
                </Box>
            </Stack>
        </Stack>
    );
}

DeliveryStageProgress.propTypes = {
    status: PropTypes.string
};
