/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
// react
import { useState, useEffect, useMemo } from 'react';
// material-ui
import { Box, Stepper, Step, StepLabel } from '@mui/material';
// import { CheckCircle, LocalShipping } from '@mui/icons-material';
// imports
import { SHIPMENT_STATUS } from '../../../../constants';

export default function StatusBar({ currentPhase }) {
    const steps = useMemo(() => Object.values(SHIPMENT_STATUS), []);
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (steps && currentPhase) {
            steps.map((step, i) => {
                if (step === currentPhase) setActiveStep(i);
            });
        }
    }, [steps, currentPhase]);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel>{step}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}

StatusBar.propTypes = {
    currentPhase: PropTypes.any
};
