import axios from 'axios';

const axiosFactory = ({ url, headers }) => {
    const instance = axios.create({
        baseURL: url,
        headers
    });

    const setToken = (token) => {
        if (!token) {
            delete instance.defaults.headers.common.Authorization;
        } else instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    };

    return {
        instance,
        setToken
    };
};

export default axiosFactory;
