import { createSlice } from '@reduxjs/toolkit';
import { axiosCatalog } from 'hooks/axiosInstance';

// project imports
import { dispatch } from '../index';

const initialState = {
    catalog: {},
    catalogTypes: [],
    catalogValues: [],
    catalogsList: [],
    cargoStatuses: [],
    packages: []
};

const slice = createSlice({
    name: 'catalogs',
    initialState,
    reducers: {
        getCatalogSuccess(state, action) {
            state.catalog = action.payload;
        },
        getCatalogTypeSuccess(state, action) {
            state.catalogTypes = action.payload;
        },
        getCatalogsSucces(state, action) {
            state.catalogValues = action.payload;
        },
        getCatalogTypeList(state, action) {
            state.catalogsList = action.payload;
        },
        getCargoStatusSuccess(state, action) {
            state.cargoStatuses = action.payload;
        },
        getPackagesSuccess(state, action) {
            state.packages = action.payload;
        }
    }
});

export default slice.reducer;

export function getCatalogTypes() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('api/catalogTypes');
            dispatch(slice.actions.getCatalogTypeSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getCatalogById(id) {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get(`api/management/catalogs/${id}`);
            dispatch(slice.actions.getCatalogSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function getCatalogs(name) {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get(`api/management/catalogs/type_name/${name}`);
            dispatch(slice.actions.getCatalogsSucces(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCatalogType(id) {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get(`api/Catalogs/type-id/${id}`);
            dispatch(slice.actions.getCatalogTypeList(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postAddNewDepartment(values, callback) {
    return async () => {
        try {
            const response = await axiosCatalog.instance.post('api/management/Catalogs', {
                name: values.displayName,
                displayName: values.displayName,
                description: values.description,
                catalogTypeId: values.catalogTypeId
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCargoStatus() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('/api/Catalogs/type-id/8c8911b9-9a85-47fe-9831-d113c9e5ceac');
            dispatch(slice.actions.getCargoStatusSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getPackages() {
    return async () => {
        try {
            const response = await axiosCatalog.instance.get('api/Catalogs/type-id/72946bcc-471b-47f1-985c-a4e0fd87b80a');
            dispatch(slice.actions.getPackagesSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
