import { Stack, Avatar, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import PropTypes from 'prop-types';

export default function PersonCard({ name, address }) {
    return (
        <Stack direction="row" spacing="10px">
            <Avatar
                variant="rounded"
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgba(20, 93, 242, 0.2)',
                    borderRadius: '8px'
                }}
            >
                <Person sx={{ color: '#145DF2', width: '30px', height: '30px' }} />
            </Avatar>
            <Stack spacing="5px">
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#0A0A0A'
                    }}
                >
                    {name}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        color: '#0A0A0A',
                        opacity: '0.5'
                    }}
                >
                    {address}
                </Typography>
            </Stack>
        </Stack>
    );
}

PersonCard.propTypes = {
    name: PropTypes.string,
    address: PropTypes.any
};
