/* eslint-disable no-nested-ternary */
import { CircularProgress, Box, Stack, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NumbersIcon from '@mui/icons-material/Numbers';
import PropTypes from 'prop-types';

const ProgressWithBackdrop = ({ value }) => (
    <Box position="relative" display="inline-flex" color="inherit">
        <CircularProgress color="inherit" variant="determinate" value={100} style={{ opacity: 0.3 }} />
        <Box
            color="inherit"
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress color="inherit" variant="determinate" value={value} />
        </Box>
    </Box>
);

ProgressWithBackdrop.propTypes = {
    value: PropTypes.any
};

const Kpis = ({ shipmentList }) => {
    const shipmentTotal = shipmentList.length;
    const shipmentsCreated = shipmentList.filter((shipment) => shipment.status === 'CREATED').length;
    const shipmentsInTransit = shipmentList.filter((shipment) => shipment.status === 'IN TRANSIT').length;
    const shipmentsDelivered = shipmentList.filter((shipment) => shipment.status === 'DELIVERED').length;
    const kpis = [
        { label: 'Total', value: shipmentTotal, percentage: 100, color: '#3f51b5' },
        { label: 'Created', value: shipmentsCreated, percentage: (shipmentsCreated / shipmentTotal) * 100, color: '#145DF2' },
        { label: 'In Transit', value: shipmentsInTransit, percentage: (shipmentsInTransit / shipmentTotal) * 100, color: '#F9C844' },
        { label: 'Delivered', value: shipmentsDelivered, percentage: (shipmentsDelivered / shipmentTotal) * 100, color: '#01C5BA' }
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                border: '1px solid rgba(100, 100, 100, .1)',
                borderRadius: '10px', // Rounded corners
                height: '10%',
                width: '100%', // Adjust the width as per your requirements
                alignItems: 'center',
                justifyContent: 'space-between',
                overflow: 'hidden' // Ensure that the child borders don't spill out of the rounded parent
            }}
            mb={2}
        >
            {kpis.map((kpi, index) => (
                <Box
                    key={index}
                    sx={{
                        flex: 1,
                        height: '100%',
                        borderRight: index !== 3 ? '1px dashed rgba(0, 0, 0, .1)' : 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white'
                    }}
                    p={2}
                >
                    <Stack direction="row" spacing={2}>
                        <Box
                            style={{
                                color: kpi.color || 'black'
                            }}
                            position="relative"
                            display="inline-flex"
                        >
                            <ProgressWithBackdrop value={kpi.percentage} />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {kpi.label === 'Total' && <NumbersIcon fontSize="medium" color="inherit" />}
                                {kpi.label === 'Created' && <AddBoxIcon fontSize="medium" color="inherit" />}
                                {kpi.label === 'In Transit' && <RestartAltIcon fontSize="medium" color="inherit" />}
                                {kpi.label === 'Delivered' && <DoneAllIcon fontSize="medium" color="inherit" />}
                            </Box>
                        </Box>
                        <Stack>
                            <Typography variant="h4">{kpi.label}</Typography>
                            <Typography variant="subtitle2">{kpi.value} shipments</Typography>
                        </Stack>
                    </Stack>
                </Box>
            ))}
        </Box>
    );
};

Kpis.propTypes = {
    shipmentList: PropTypes.array
};

export default Kpis;
