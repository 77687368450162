// third-party
import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import groupReducer from './slices/groups';
import organizationReducer from './slices/organization';
import catalogsReducer from './slices/catalogs';
import documentsReducer from './slices/documents';
import quotesReducer from './slices/quotes';
import shipmentReducer from './slices/shipments';
import parcelsReducer from './slices/parcels';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    user: userReducer,
    menu: menuReducer,
    groups: groupReducer,
    organization: organizationReducer,
    catalogs: catalogsReducer,
    documents: documentsReducer,
    quotes: quotesReducer,
    shipments: shipmentReducer,
    parcels: parcelsReducer
});

export default reducer;
