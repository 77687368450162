import { createSlice } from '@reduxjs/toolkit';
// import axios from 'hooks/axiosInstance';
import { axiosPeople } from 'hooks/axiosInstance';

// project imports
import { dispatch } from '../index';

const initialState = {
    error: null,
    organizationList: [],
    orgBillingInfo: [],
    orgShippingInfo: [],
    orgTerms: {},
    orgContacts: [],
    orgUsers: [],
    contactPost: '',
    orgsTypeCustomer: [],
    orgDataById: {},
    organizationMemeber: [],
    orgCarries: []
};

const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        getOrganizationListSuccess(state, action) {
            state.organizationList = action.payload;
        },
        getOrganizationBillingInfoSuccess(state, action) {
            state.orgBillingInfo = action.payload;
        },
        getOrganizationShippingSuccess(state, action) {
            state.orgShippingInfo = action.payload;
        },
        getOrganizationTermsSuccess(state, action) {
            state.orgTerms = action.payload;
        },
        getOrganizationContactsSuccess(state, action) {
            state.orgContacts = action.payload;
        },
        getOrganizationUsersSuccess(state, action) {
            state.orgUsers = action.payload;
        },
        postOrganizationContactSuccess(state, action) {
            state.contactPost = action.payload;
        },
        getOrganizationsOfTypeCustomerSuccess(state, action) {
            state.orgsTypeCustomer = action.payload;
        },
        getOrganizationByIdReducer(state, action) {
            state.orgDataById = action.payload;
        },
        getOrganizationMember(state, action) {
            state.organizationMemeber = action.payload;
        },
        getOrganizationCarries(state, action) {
            state.orgCarries = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getOrganizationList(pageSize, page, type, searchInput, status) {
    const paramValues = [
        ['PageSize', pageSize],
        ['Page', page],
        ['Filter', searchInput],
        ['Type', type],
        ['Status', status]
    ];
    if (!type) {
        paramValues.splice(3, 1);
    }
    if (!status) {
        paramValues.splice(paramValues.length - 1, 1);
    }
    const params = new URLSearchParams(paramValues);
    return async () => {
        try {
            const response = await axiosPeople.instance.get('/api/organizations', { params });
            dispatch(slice.actions.getOrganizationListSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getOrganizationById(organizationId) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/organizations/${organizationId}`);
            dispatch(slice.actions.getOrganizationByIdReducer(response.data));
        } catch (e) {
            console.log(e);
        }
    };
}

export function getBillingInfo(id) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/Organizations/${id}/billing-information`);
            dispatch(slice.actions.getOrganizationBillingInfoSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getShippingInfo(id) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/organizations/${id}/shipping-information`);
            // console.log(response.data);
            dispatch(slice.actions.getOrganizationShippingSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getTermsInfo(id) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/organizations/${id}/terms`);
            dispatch(slice.actions.getOrganizationTermsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getContactsInfo(id) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/organizations/${id}/contacts`);
            dispatch(slice.actions.getOrganizationContactsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postOrgContact(orgId, contactData, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.post(`/api/organizations/${orgId}/contacts`, contactData);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function putOrgContact(orgId, contactData, phone, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/organizations/${orgId}/contacts/${phone}`, contactData);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getUsersInfo(orgId) {
    // const params = new URLSearchParams([
    //     ['Page', page],
    //     ['PageSize', pageSize],
    //     ['Organization', orgId],
    //     ['Filter', filter]
    // ]);
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/organizations/${orgId}/members`);
            dispatch(slice.actions.getOrganizationUsersSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
export function postOrgUser(userData, orgId, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.post(`/api/Organizations/${orgId}/members`, userData);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function putOrgUser(userData, userId, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/customers/${userId}`, userData);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function postOrgNewBilling(id, billingInfo, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.post(`/api/organizations/${id}/billingInformation`, billingInfo);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function updateBillingInfo(billingId, values, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`api/BillingInformations/${billingId}`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function postOrgNewShipping(id, shippingInfo, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.post(`/api/organizations/${id}/shippingInformation`, shippingInfo);
            console.log(response);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function updateShippingInfo(orgId, values, shippingId, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/organizations/${orgId}/shippingInformation/${shippingId}`, values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function setBillingDefault(orgId, billingId, callback) {
    // const params = new URLSearchParams([['billingInformationId', billingId]]);
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/organizations/${orgId}/billingInformation/default`, {
                billingInformationId: billingId
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function setShippingDefault(orgId, shippingId, callback) {
    // const params = new URLSearchParams([['billingInformationId', billingId]]);
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/organizations/${orgId}/shippingInformation/default`, {
                shippingInformationId: shippingId
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function postOrgDocuments(billingId, data, callback) {
    console.log(billingId, data);
    return async () => {
        try {
            const formData = new FormData();
            const keys = Object.keys(data);

            keys.forEach((key) => formData.append(key, data[key]));

            const response = await axiosPeople.instance.put(`/api/BillingInformations/${billingId}/add-document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
export function deleteOrgDocuments(name, callBack) {
    return async () => {
        try {
            const response = await axiosPeople.instance.delete(`api/management/files/${name}`);
            callBack(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getOrgTypeCustomer(page, pageSize, typeId, searchCustomer) {
    const params = new URLSearchParams([
        ['Page', page],
        ['PageSize', pageSize],
        ['InternalId', ''],
        ['ExternalId', ''],
        ['TypeId', typeId],
        ['Filter', searchCustomer]
    ]);

    return async () => {
        try {
            const response = await axiosPeople.instance.get('api/Organizations', { params });
            dispatch(slice.actions.getOrganizationsOfTypeCustomerSuccess(response.data.data));
        } catch (e) {
            console.log(e);
        }
    };
}

export function getCustomer(id, callback) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`/api/Customers/${id}`);
            callback(response);
        } catch (e) {
            console.log(e);
        }
    };
}

export function postBillingDocs(id, type, doc, callback) {
    const formData = new FormData();

    formData.append('Type', type);
    formData.append('File', doc);
    return async () => {
        try {
            const response = await axiosPeople.instance.put(`/api/BillingInformations/${id}/add-document`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function getMemberByOrganizationId(id) {
    return async () => {
        try {
            const response = await axiosPeople.instance.get(`api/Organizations/${id}/members`);
            dispatch(slice.actions.getOrganizationMember(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function getCarriers() {
    const params = new URLSearchParams([
        ['PageSize', 30],
        ['Page', 1],
        ['Filter', 'carrier']
    ]);

    return async () => {
        try {
            const response = await axiosPeople.instance.get('/api/organizations', { params });
            dispatch(slice.actions.getOrganizationCarries(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
