// assets
import { IconSettings, IconBuilding } from '@tabler/icons';

// constant
const icons = {
    IconSettings,
    IconBuilding
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const settings = {
    id: 'settings-section',
    type: 'group',
    rolesGuard: ['ParcelMaker'],
    children: [
        {
            id: 'myOrganization',
            title: 'My Organization',
            type: 'item',
            url: '/my-organization',
            icon: icons.IconBuilding,
            breadcrumbs: true,
            rolesGuard: ['Owner']
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/management',
            icon: icons.IconSettings,
            breadcrumbs: false,
            rolesGuard: ['ParcelMaker']
        }
    ]
};

export default settings;
