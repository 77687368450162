import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// MUI
import { DashboardOutlined, FormatListBulletedRounded } from '@mui/icons-material';
import { Stack, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';

// This component is used to switch between different views.
export default function ViewsBox({ view, setView, link, linkMsg }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { user } = useAuth();
    // When the user clicks on the cards icon, the view is set to 'grid'.
    const onCardsClick = () => {
        setView('grid');
    };

    // When the user clicks on the list icon, the view is set to 'list'.
    const onListClick = () => {
        setView('list');
    };
    return (
        <Stack
            direction="row"
            sx={{
                height: '50px',
                maxWidth: '180px',
                marginInline: 'auto'
            }}
            alignItems="center"
            justifyContent="space-evenly"
            spacing={3}
        >
            <IconButton onClick={onCardsClick}>
                <DashboardOutlined
                    sx={{
                        color: view === 'grid' ? theme.palette.primary.main : '',
                        cursor: 'pointer'
                    }}
                />
            </IconButton>
            <IconButton onClick={onListClick}>
                <FormatListBulletedRounded
                    sx={{
                        color: view === 'list' ? theme.palette.primary.main : '',
                        cursor: 'pointer'
                    }}
                />
            </IconButton>
            {user.role === '123' && (
                <>
                    {link && (
                        <Tooltip title={linkMsg} placement="top">
                            <IconButton type="button" color="primary" onClick={() => navigate(link)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            )}
        </Stack>
    );
}

ViewsBox.propTypes = {
    view: PropTypes.any,
    setView: PropTypes.any,
    link: PropTypes.string,
    linkMsg: PropTypes.string
};
