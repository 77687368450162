import { useState } from 'react';
import { Input, Stack, IconButton } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

// Search box component.
// Calls onSubmitSearch when the user presses enter or clicks on the search icon.
export default function SearchBox({ onSubmitSearch }) {
    // Search input state.
    const [searchInput, setSearchInput] = useState('');

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            spacing="10px"
            sx={{
                backgroundColor: 'white',
                height: '50px',
                borderRadius: '8px',
                border: '1px solid rgba(147, 166, 192, 0.35)',
                padding: '0 16px'
            }}
        >
            {' '}
            <IconButton
                onClick={() => {
                    // Call onSubmitSearch when the user clicks on the search icon.
                    onSubmitSearch(searchInput);
                }}
            >
                <SearchRounded sx={{ fontSize: '25px' }} />
            </IconButton>
            <Input
                fullWidth
                disableUnderline
                placeholder="Search here..."
                sx={{
                    fontSize: '16px',
                    '& .MuiInput-input::placeholder': {
                        color: '#0A0A0A',
                        opacity: 0.5,
                        fontSize: '16px'
                    }
                }}
                value={searchInput}
                onChange={(e) => {
                    setSearchInput(e.target.value.trim());
                    onSubmitSearch(e.target.value.trim());
                }}
                onKeyDown={(e) => {
                    // If the user presses enter, call onSubmitSearch.
                    if (e.key === 'Enter') {
                        e.target.blur();
                        onSubmitSearch(searchInput.trim());
                    }
                }}
            />
        </Stack>
    );
}

SearchBox.propTypes = {
    onSubmitSearch: PropTypes.any
};
