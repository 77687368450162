import { createSlice } from '@reduxjs/toolkit';
import { axiosVonto, axiosUserAccess } from 'hooks/axiosInstance';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    employee: {},
    employeeList: {},
    createNewUserSuccess: [],
    employeeDeparments: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET SINGLE USER
        getEmployeeSuccess(state, action) {
            state.employee = action.payload;
        },
        // GET EMPLOYEE LIST
        getEmployeeListSuccess(state, action) {
            state.employeeList = action.payload;
        },
        createNewUserSuccess(state, action) {
            state.createNewUserSuccess = action.payload;
        },
        getEmployeeDepartmentSuccess(state, action) {
            state.employeeDeparments = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getEmployeeDetails(id) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/employees/employees/${id}`);
            dispatch(slice.actions.getEmployeeSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getEmployeeList(departmentQuery, roleQuery, page, pageLimit, searchInput) {
    const params = new URLSearchParams([
        ['Role', roleQuery],
        ['Department', departmentQuery],
        ['Page', page],
        ['PageLimit', pageLimit],
        ['Filter', searchInput]
    ]);
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/employees/employees', { params });
            dispatch(slice.actions.getEmployeeListSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postEditUserInfo(data, idUser, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.put(`api/employees/employees/${idUser}`, data);
            callback(response);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function postCreateNewEmployee(data) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post('api/employees/employees', data);
            dispatch(slice.actions.createNewUserSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getEmployeeDeparments() {
    return async () => {
        try {
            const response = await axiosVonto.instance.get('/api/employees/Departments');
            dispatch(slice.actions.getEmployeeDepartmentSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}

export function postNewDepartment(values, callback) {
    return async () => {
        try {
            const response = await axiosVonto.instance.post('/api/employees/Departments', values);
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}

export function setProfilePic(doc, callback) {
    const formData = new FormData();

    formData.append('ProfilePicture', doc);
    return async () => {
        try {
            const response = await axiosUserAccess.instance.put('/api/Account/SetProfilePicture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            callback(response);
        } catch (error) {
            console.log(error);
        }
    };
}
