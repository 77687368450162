import { Person } from '@mui/icons-material';
import { Stack } from '@mui/material';

// Icon used to represent a person.
export default function PersonSquare() {
    return (
        <Stack
            sx={{
                width: '36px',
                height: '36px',
                borderRadius: '8px',
                backgroundColor: 'rgba(20, 93, 242, 0.2)'
            }}
            justifyContent="center"
            alignItems="center"
        >
            <Person
                sx={{
                    color: '#145DF2',
                    fontSize: '30px'
                }}
            />
        </Stack>
    );
}
