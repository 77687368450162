/* eslint-disable no-nested-ternary */
// material-ui
import { Circle } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { SHIPMENT_STATUS } from '../../../../constants';

// deliveryStage takes a value from 0 to 2, 0 is Created, 1 is In Transit, 2 is Delivered
export default function StatusLabel({ status }) {
    // Switch color based on status
    const circleColor =
        status === SHIPMENT_STATUS.created
            ? '#145DF2'
            : status === SHIPMENT_STATUS.inTransit
            ? '#F9C844'
            : status === SHIPMENT_STATUS.delivered && '#01C5BA';

    // Switch text based on status
    const text =
        status === SHIPMENT_STATUS.created
            ? 'Created'
            : status === SHIPMENT_STATUS.inTransit
            ? 'In Transit'
            : status === SHIPMENT_STATUS.delivered && 'Delivered';

    return (
        <Stack direction="row" spacing="6px" alignItems="center" justifyContent="start">
            <Circle sx={{ fontSize: '13px', color: circleColor }} />
            <Typography sx={{ color: '#0A0A0A', fontSize: '13px' }}>{text}</Typography>
        </Stack>
    );
}

StatusLabel.propTypes = {
    status: PropTypes.string
};
