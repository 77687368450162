import { createSlice } from '@reduxjs/toolkit';
// import axios from 'hooks/axiosInstance';
import { axiosVonto } from 'hooks/axiosInstance';

// project imports
import { dispatch } from '../index';

const initialState = {
    error: null,
    orgBillingDocs: {}
};

const slice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        getDocsSuccess(state, action) {
            state.orgBillingDocs = action.payload;
        }
    }
});

export default slice.reducer;

export function getDocuments(name) {
    return async () => {
        try {
            const response = await axiosVonto.instance.get(`/api/management/files/get/base64/${name}`);
            dispatch(slice.actions.getDocsSuccess(response.data));
        } catch (error) {
            console.log(error);
        }
    };
}
