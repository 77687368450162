// react
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material-ui
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import StatusLabel from './StatusLabel';
import { Stack } from '@mui/system';

// Table columns. Includes column name, width, and getter function.
// Getter gets an item from the shipment list and returns the JSX to be displayed in the column.
const columns = [
    {
        sortable: false,
        headerName: 'Shipment Number',
        field: 'externalId',
        minWidth: 178,
        renderCell: ({ row }) => <Typography>{row.externalId} </Typography>,
        flex: 1
    },
    {
        sortable: false,
        headerName: 'Customer Reference Number',
        field: 'customerReferenceNumber',
        minWidth: 175,
        renderCell: ({ row }) => <Typography>{row.customerReferenceNumber}</Typography>,
        flex: 1
    },
    {
        sortable: false,
        headerName: 'Origin',
        field: 'origin',
        minWidth: 130,
        renderCell: ({ row }) => <Typography>{row.origin}</Typography>,
        flex: 1
    },
    {
        sortable: false,
        headerName: 'Delivery',
        field: 'destination',
        minWidth: 130,
        renderCell: ({ row }) => <Typography>{row.destination}</Typography>,
        flex: 1
    },
    {
        sortable: false,
        headerName: 'Container',
        field: 'containerType',
        minWidth: 180,
        renderCell: ({ row }) => (
            <Stack direction="row" spacing={0.5}>
                <Typography component="span" sx={{ color: '#145DF2' }}>
                    {row.package.Quantity ?? 0}
                </Typography>
                <Typography>x</Typography>
                <Typography>{row.shipmentContainerType}</Typography>
            </Stack>
        ),
        flex: 1
    },
    {
        headerName: 'Estimated Time of Arrival',
        minWidth: 195,
        field: 'estimatedTimeOfArrival',

        renderCell: ({ row }) => <Typography>{row.estimatedTimeOfArrival}</Typography>,
        flex: 1
    },
    // {
    //     headerName: 'Price/Kg',
    //     minWidth: 120,
    //     field: 'priceByKg',

    //     renderCell: ({ row }) => <Typography>${row.price.priceByKg}</Typography>,
    //     flex: 1
    // },
    // {
    //     headerName: 'Total Price',
    //     minWidth: 140,
    //     field: 'total',

    //     renderCell: ({ row }) => <Typography>${row.price.total}</Typography>,
    //     flex: 1
    // },
    {
        sortable: false,
        headerName: 'Status',
        field: 'status',
        minWidth: 130,
        renderCell: ({ row }) => <StatusLabel status={row.status} />,
        flex: 1
    }
];

export default function ShipmentList({ shipmentList }) {
    const [pageSize, setPageSize] = useState(12);
    const navigate = useNavigate();

    const handleEvent = (
        params // GridRowParams
    ) => {
        navigate(params.row.id);
    };

    return (
        <Box>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-footerContainer': {
                        height: '60px'
                    },
                    width: '100%',
                    overflow: 'hidden',
                    backgroundColor: 'white',
                    height: {
                        md: 'calc(100vh - 200px)',
                        sm: 'calc(100vh - 260px)',
                        xs: 'calc(100vh - 260px)'
                    },
                    '&:hover': {
                        cursor: 'pointer'
                    }
                }}
                rows={shipmentList}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 15, 30, 40]}
                onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                }}
                // disableColumnMenu
                checkboxSelection
                onRowClick={handleEvent}
            />
        </Box>
    );
}

ShipmentList.propTypes = {
    shipmentList: PropTypes.arrayOf(PropTypes.any)
};
